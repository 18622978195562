<template>
    <section class="mb-4">
        <v-carousel :show-arrows="false" hide-delimiters style="" cycle infinite class="size">
            <v-carousel-item src="Resources/images/diapo/maison.jpg" cover style="position:relative;">
                <div class="d-flex align-center" style="position:absolute; width:100%; height:100%;">
                    <v-row>
                        <v-col cols="12" class="d-flex justify-center">
                            <h2 class="diapoTxt oswald2"><span class='bold uppercase'>{{$t('diapo.text')}}</span></h2>
                        </v-col>
                    </v-row>
                    
                </div>
            </v-carousel-item>
            <!--<v-carousel-item src="Resources/images/diapo/edgar-zbvdNw4fCII-unsplash.jpg" cover style="position:relative;"></v-carousel-item>-->

            <v-carousel-item src="Resources/images/diapo/john-paulsen-MJeyFglfq9E-unsplash.jpg" cover style="position:relative;"></v-carousel-item>
        </v-carousel>
    </section>
</template>
<style scoped>
    .diapoTxt {
        line-height: 5vw; 
        font-size: 7vw !important;  color: rgba(0, 0, 0, 0.5); 
        /*shadow: 0 0 .5em #F00;*/
    }
    .btn {
        color: rgb(var(--tierce)); 
        background-color: rgb(var(--primary)); 
        font-size: 16px!important;
    }
    .size {height:600px;}

    @media handheld, only screen and (max-width: 600px) {
    }
    @media handheld, only screen and (max-width: 960px) {
        .btn {font-size: 12px !important;}
        .size {height:250px !important;}
    }
    @media handheld, only screen and (max-width: 1264px) {}  
</style>
<script>
import translations from './../assets/i18N/global.json';

  export default {
    name: 'Diapo',
    data: () => ({

    }),
  beforeCreate: function () {
        let lang = localStorage.getItem("Lang");
        if(lang){this.$i18n.locale = lang;} else { this.$i18n.locale = 'fr'; }
 
        this.$i18n.mergeLocaleMessage('fr', translations.fr);
        this.$i18n.mergeLocaleMessage('en', translations.en);
    },
    mounted() {
        
    },
    methods:{

    },
    component: {

    }
    
  }
</script>
<template>
    <v-row>
        <v-col col="12">
            <v-btn class="ma-5 nextButton"  @click="$emit('more')">Suivant</v-btn>
        </v-col>
    </v-row>

</template>

<style scoped>
    .nextButton {background-color: rgb(var(--primary)); padding: 0px 20px !important; color: white; float: right;}
    .nextButton:hover {background-color: rgb(var(--secondary)); }
</style>

<script>
    export default {
        name: 'partial-list-navigation',
        props: ['response'],
        computed: {
            contentRange: function () {
                if (this.response) {
                    return this.response.headers.get('Content-Range');
                }
            }
        }
    }
</script>
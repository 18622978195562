<template>
    <div>
        
        <!--Desktop-->
        <div v-if="ready" >
            <v-row class="summaryBG summary">
                <v-col class="f-white pa-0 uppercase">
                    <v-row>
                        <v-col class="d-flex justify-center">
                            <h2 v-html="$t('summary.title')"></h2>
                        </v-col>
                    </v-row>

                    <div v-if="this.current" >
                        <v-row class="ma-0">
                            <v-col class="pa-0 d-flex justify-start"><p class="sumInfo" v-html="$t('summary.subtotal')"></p></v-col>
                            <v-col class="pa-0 d-flex justify-end f-primary">
                                <div v-if="!dirty"><p class="sumInfo" >{{ $filters.$(this.current.subTotal) }}$</p></div>
                                <div v-else>----</div>
                            </v-col>
                        </v-row>
                        <v-row class="ma-0">
                            <v-col class="pa-0 d-flex justify-start"><p class="sumInfo" v-html="$t('summary.delivery')"></p> </v-col>
                            <v-col class="pa-0 d-flex justify-end f-primary">
                                <div v-if="!dirty"><p class="sumInfo">{{ $filters.$(this.current.shipping) }}$</p></div>
                                <div v-else>----</div>
                            </v-col>
                        </v-row>
                        <v-row class="ma-0">
                            <v-col class="pa-0 d-flex justify-start"><p class="sumInfo" v-html="$t('summary.gst')"></p></v-col>
                            <v-col class="pa-0 d-flex justify-end f-primary">
                                <div v-if="!dirty"><p class="sumInfo">{{ $filters.$(this.current.gst) }}$</p></div>
                                <div v-else>----</div>
                            </v-col>
                        </v-row>
                        <v-row class="ma-0">
                            <v-col class="pa-0 d-flex justify-start"><p class="sumInfo" v-html="$t('summary.qst')"></p></v-col>
                            <v-col class="pa-0 d-flex justify-end f-primary">
                                <div v-if="!dirty"><p class="sumInfo">{{ $filters.$(this.current.pst) }}$</p></div>
                                <div v-else>----</div>
                            </v-col>
                        </v-row>
                        <hr class="mt-4" style="width:70%; display: block; margin-left: auto !important; margin-right: auto !important;">
                        <v-row class="mt-4 mx-0">
                            <v-col class="pa-0 d-flex justify-start"><p v-html="$t('summary.total')"></p></v-col>
                            <v-col class="pa-0 d-flex justify-end f-primary">
                                <div v-if="!dirty"><p>{{ $filters.$(this.current.total) }}$</p></div>
                                <div v-else>----</div>
                            </v-col>
                        </v-row>
                    </div>

                    <!-- v-btn-->
                    <v-row class="ma-0">
                        <v-col cols="12" class="d-flex justify-center">
                            <v-btn v-if="current && current.items.length == 0 && dirty == false"  class="mt-1 orderBtn">
                                <div @click="navigateTo">        
                                    <h2 class="f-white light" v-html="$t('btn.shop')"></h2>
                                </div>
                            </v-btn>
                            <v-btn v-if="current && dirty == true" @click="reEvaluate"  class="mt-1 orderBtn">
                                <div >   
                                    <h2 class="f-white light" v-html="$t('btn.refresh')"></h2>
                                </div>
                            </v-btn>
                            <v-btn v-if="current && Step != 'paiement' && current.items.length > 0 && dirty == false" :class="{'gray': loading == true }" class=" orderBtn mt-1" :readonly="loading == true">
                                <div @click="CompletedStep">
                                    <h2 :class="{'gray': loading == true }" v-if="loading == true" class="f-white" style="font-size:20px;" v-html="$t('btn.processing')"></h2>
                                    <h2 v-if="loading == false" class="f-white light" style="font-size:34px;" v-html="$t('btn.pay')"></h2>                    
                                </div>
                            </v-btn>
                        </v-col>

                        <v-col class="d-flex justify-center mt-n6">
                            <p class="linkText oswald2" @click="this.backStep()" v-html="$t('link.back')"></p>
                        </v-col>

                        <v-col  cols="12" class="pa-0" style="height:5px;">
                            <v-progress-linear v-if="loading == true" class="" color="rgb(var(--secondary))" indeterminate striped height="8"></v-progress-linear>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>

        </div>

        <!--Mobile-->
        <div v-if="ready" class="mobile">
            <v-row>
                <v-col cols="12">
                    <v-col class="d-flex justify-center">
                        <h2 class="bold uppercase" v-html="$t('summary.title')"></h2>
                    </v-col>
                    
                    <div v-if="this.current" >
                        <v-row class="ma-0">
                            <v-col class="pa-0 d-flex justify-start"><p class="sumInfo" v-html="$t('summary.subtotal')"></p></v-col>
                            <v-col class="pa-0 d-flex justify-end f-primary">
                                <div v-if="!dirty"><p class="sumInfo" >{{ $filters.$(this.current.subTotal) }}$</p></div>
                                <div v-else>----</div>
                            </v-col>
                        </v-row>
                        <v-row class="ma-0">
                            <v-col class="pa-0 d-flex justify-start"><p class="sumInfo" v-html="$t('summary.delivery')"></p> </v-col>
                            <v-col class="pa-0 d-flex justify-end f-primary">
                                <div v-if="!dirty"><p class="sumInfo">{{ $filters.$(this.current.shipping) }}$</p></div>
                                <div v-else>----</div>
                            </v-col>
                        </v-row>
                        <v-row class="ma-0">
                            <v-col class="pa-0 d-flex justify-start"><p class="sumInfo" v-html="$t('summary.gst')"></p></v-col>
                            <v-col class="pa-0 d-flex justify-end f-primary">
                                <div v-if="!dirty"><p class="sumInfo">{{ $filters.$(this.current.gst) }}$</p></div>
                                <div v-else>----</div>
                            </v-col>
                        </v-row>
                        <v-row class="ma-0">
                            <v-col class="pa-0 d-flex justify-start"><p class="sumInfo" v-html="$t('summary.qst')"></p></v-col>
                            <v-col class="pa-0 d-flex justify-end f-primary">
                                <div v-if="!dirty"><p class="sumInfo">{{ $filters.$(this.current.pst) }}$</p></div>
                                <div v-else>----</div>
                            </v-col>
                        </v-row>
                        <hr class="mt-4" style="width:70%; display: block; margin-left: auto !important; margin-right: auto !important;">
                        <v-row class="mt-4 mx-0">
                            <v-col class="pa-0 d-flex justify-start"><p v-html="$t('summary.total')"></p></v-col>
                            <v-col class="pa-0 d-flex justify-end f-primary">
                                <div v-if="!dirty"><p>{{ $filters.$(this.current.total) }}$</p></div>
                                <div v-else>----</div>
                            </v-col>
                        </v-row>
                    </div>

                    <!--v-btn-->
                    <v-row class="ma-0">
                        <v-col cols="12" class="d-flex justify-center">
                            <v-btn v-if="current && current.items.length == 0 && dirty == false"  class="mt-1 orderBtn">
                                <div @click="navigateTo">        
                                    <h2 class="f-white light" v-html="$t('btn.shop')"></h2>
                                </div>
                            </v-btn>
                            <v-btn v-if="current && dirty == true" @click="reEvaluate"  class="mt-1 orderBtn">
                                <div >   
                                    <h2 class="f-white light" v-html="$t('btn.refresh')"></h2>
                                </div>
                            </v-btn>
                            <v-btn v-if="current && Step != 'paiement' && current.items.length > 0 && dirty == false" :class="{'gray': loading == true }" class=" orderBtn mt-1" :readonly="loading == true">
                                <div @click="CompletedStep">
                                    <h2 :class="{'gray': loading == true }" v-if="loading == true" class="f-white" style="font-size:20px;" v-html="$t('btn.processing')"></h2>
                                    <h2 v-if="loading == false" class="f-white light" style="font-size:34px;" v-html="$t('btn.pay')"></h2>                    
                                </div>
                            </v-btn>
                        </v-col>

                        <v-col class="d-flex justify-center mt-n6">
                            <p class="linkText oswald2" @click="this.backStep()" v-html="$t('link.back')"></p>
                        </v-col>

                        <v-col  cols="12" class="pa-0" style="height:5px;">
                            <v-progress-linear v-if="loading == true" class="" color="rgb(var(--secondary))" indeterminate striped height="8"></v-progress-linear>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </div>
        
    </div>
    
</template>

<style scoped>
    .summary {display: block;}
    .mobile{display: none; padding: 0px 50px 0px 50px}
    .summaryBG { position:fixed; top: 190px; right:25px ;height: auto; min-width:350px; max-width:600px;padding: 20px; background-color: rgba(var(--secondary),0.9); z-index: 100;}
    .priceTitle {color: rgb(var(--tierce)); text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);}
    .price {color: rgb(var(--secondary)); text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); font-weight: 700;}
    .orderBtn{display: block; height:50px !important; background-color: transparent; border: 1px solid gray; border-radius: 10px; width:240px;}
    .orderBtn:hover {background-color: rgb(var(--primary));}
    .orderBtnTitle {font-weight: lighter; color: rgb(var(--tierce));}
    .gray {color: dimgray; background-color: gray; border-color: black;}
    .gray:hover {background-color: gray !important; }
    .linkText { margin-top:3px; color:gray; cursor: pointer;}
    .linkText:hover {color: rgb(var(--primary))}
    .storeText {color: rgb(var(--secondary)); text-decoration: underline; cursor: pointer; }
    .storeText:hover {text-decoration: none;}
    .h2Mobile{font-family: Ephesis; font-size: 36px; color: rgb(var(--secondary));}
    .detailedText { font-size: 11px; margin-top: -5px !important; text-align: center;   color: rgb(var(--tierce)); text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);}
    .sumInfo {font-size: 14px;}
    @media handheld, only screen and (max-width: 600px) {}
    @media handheld, only screen and (max-width: 960px) {
        .summary {display: none;}
        .mobile{display: block; padding: 0px 10px;}
        .backText {color: rgb(var(--primary));}
        .priceTitle {color: rgb(var(--primary));}
        .orderBtnTitle {color: rgb(var(--secondary));}
        .orderBtnTitle:hover {color: rgb(var(--tierce));}
        .storeText {color: rgb(var(--primary));}
        .detailedText {color: rgb(var(--primary)); text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);}
        .linkText { font-size:12px !important;}
        .orderBtn{background-color: rgb(var(--secondary)); width:100%;}
        .orderBtn:hover {background-color: rgb(var(--primary));}
    }
    @media handheld, only screen and (max-width: 1200px) { .linkText { font-size:11px;} }
    @media handheld, only screen and (max-width: 1279px) {}

</style>

<script>
import { apiECommerceExtension } from '@/mixins/apiECommerceExtension.js'
import pageTranslations from '../../src/assets/i18N/eCommerce/infoPayment.json';

export default {
    name: 'EcommerceSummary', 
    mixins: [apiECommerceExtension],
    props: {
        item: Object,
        step: String,
        dirty: Boolean,
        loading: Boolean,
    },
    data: () => ({
        current: null,
        Step: null,
        order:null,
        shippingRestrictiondate: '',
        valided: false,
        
    }),
    computed:{
        ready: async function () {
            let show = false;
            if(this.item) {
                this.current = this.item;
                show = true;
            }
            if(this.step){this.Step = this.step;}
            return show;
        },

    },
    beforeCreate: function () {
        this.$i18n.mergeLocaleMessage('fr', pageTranslations.fr);
        this.$i18n.mergeLocaleMessage('en', pageTranslations.en);
    },
    mounted() {
      this.valided = false;
    },
    methods: {
        NextStep: async function(){      
            let orderUpdated = await this.UpdateOrder(this.item);
            if(this.current.items.length == 0){this.loading = false; this.navigateTo();}
            else{ if(orderUpdated){ this.$emit("update:item", orderUpdated); this.$emit("eCommerceNext", orderUpdated); }}
            //setTimeout(() => this.loading = false, 2000);    
        },
        CompletedStep: async function(){ this.$emit("eCommerceCompleted"); },
        reEvaluate: async function(){ this.$emit("eCommerceNext"); },
        navigateTo: function () { this.$router.push('/Boutique'); },
        backStep: function(){ this.$emit("eCommerceBack"); },
        endLoading: function (){this.loading = false},
    },
    
    components: {
    },
}
</script>
<template>
    <div>
        <h3 class="f-primary" v-html="$t('billing.title')"></h3>
        <h4 class="mb-6 oswald2" v-html="$t('billing.slogan')"></h4>
        <div v-if="ready">
          <v-row class="ma-0">
            <v-col>
                <v-row class="grid-pad">
                    <v-col class="infoFieldWidth">
                        <div>
                            <v-row>
                                <h2 class="f-primary oswald2" v-html="$t('billing.infoBilling')"></h2>
                            </v-row>
                        </div>
                        <div class="mt-10">
                            <v-row>
                              <v-col cols="12"><h5 :class="$filters.validations(v$.current.account.name)" v-html="$t('billing.name')"></h5></v-col>
                              <v-col cols="12"><v-text-field  v-model="v$.current.account.name.$model" clearable required /></v-col> <!--:rules="nameRules"-->
                              <v-col cols="12" v-if="v$.current.account.name.$invalid"><p class="errorText mt-n11" v-html="$t('billing.nameError')"></p></v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12"><phone v-model:item="current.account.phone" clearable ></phone></v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12"><h5 :class="$filters.validations(v$.current.account.email.address)" v-html="$t('billing.email')"></h5></v-col>
                                <v-col cols="12"><v-text-field v-model="v$.current.account.email.address.$model" clearable required /></v-col> <!--:rules="nameRules"-->
                                <v-col cols="12" v-if="v$.current.account.email.address.$invalid" ><p class="errorText mt-n11" v-html="$t('billing.emailError')"></p></v-col>
                            </v-row>
                        </div>
                    </v-col>
                    <v-col class="infoFieldWidth" >
                        <v-row>
                          <h2 class="f-primary oswald2" v-html="$t('billing.adressBilling')"></h2>               
                        </v-row>
                        <div class=" mt-10">
                          <v-row>
                            <v-col cols="12"><h5 :class="$filters.validations(v$.current.account.address.street)" v-html="$t('billing.street')"></h5></v-col>
                            <v-col cols="12"><v-text-field @change="changeAddress()" v-model="v$.current.account.address.street.$model"  clearable required /></v-col>
                            <v-col cols="12" v-if="v$.current.account.address.street.$invalid" ><p class="errorText mt-n11" v-html="$t('billing.streetWarning')"></p></v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12"><h5 :class="$filters.validations(v$.current.account.address.city.name)" v-html="$t('billing.city')"></h5></v-col>
                            <v-col cols="12"><v-text-field @change="changeAddress()" v-model="v$.current.account.address.city.name.$model"  clearable required /></v-col>
                            <v-col cols="12" v-if="v$.current.account.address.city.name.$invalid" ><p class="errorText mt-n11" v-html="$t('billing.cityWarning')"></p></v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12"><h5 :class="$filters.validations(v$.current.account.address.zipCode)" v-html="$t('billing.zipCode')"></h5></v-col>
                            <v-col cols="12"><v-text-field @change="changeAddress()" v-model="v$.current.account.address.zipCode.$model" required/></v-col>
                            <v-col cols="12" v-if="v$.current.account.address.zipCode.$invalid" ><p class="errorText mt-n11" v-html="$t('billing.zipCodeWarning')"></p></v-col>
                          </v-row>
                        </div>
                    </v-col>
                </v-row>
            </v-col>
          </v-row>
        </div>
    </div>
</template>

<style scoped>
  h2 {font-size: 24px;}
  h3 {font-size: 32px;}
  h4 {font-size: 24px;}
  p {font-size: 16px; }
  .v-col{margin: 0px; padding: 10px;}
  .infoFieldWidth {min-width: 400px;}
  @media handheld, only screen and (max-width: 600px) {}
  @media handheld, only screen and (max-width: 754px) {
    .infoFieldWidth {min-width: 100%;}
    h2 {font-size: 18px;}
    h3 {font-size: 24px;}
    h4 {font-size: 18px;}
    p {font-size: 14px; }
  
  }
  @media handheld, only screen and (max-width: 960px) {}
  @media handheld, only screen and (max-width: 1279px) {}
  @media handheld, only screen and (max-width: 1378px) {}
  @media handheld, only screen and (max-width: 1502px) {}
</style>

<script>
import { apiECommerceExtension } from '@/mixins/apiECommerceExtension.js';
import pageTranslations from '../../../../src/assets/i18N/eCommerce/order.json';
import phone from '@/components/commons/Phone.vue';
import { required, email, minLength } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core';

export default {
    name: 'OrderBilling', 
    mixins: [apiECommerceExtension],
    props: { item: Object, dirty: Boolean, },
    setup () {  return { v$: useVuelidate() } },
    data: () => ({
        current: null,
        validationObject:[],
        //Billing
        DefaultAddress: {},
        isDirty: false,
    }),
    validations: {
      current: {
        account: {
          name:{ required },
          phone: { required, min: minLength(7) },
          email: { 
            address:{ required, email }  
          },
          address: {
            street: { required },
            city: {
              name: { required }
            },
            zipCode: { required,  min: minLength(6) }
          }
        }
      }
      
    },
    computed:{
        ready: async function () {
            let show = false;
            if(this.item) {
                this.current = this.item;
                show = true;
            }
            return show;
        },

    },
    mounted: async function () {
        this.$i18n.mergeLocaleMessage('fr', pageTranslations.fr);
        this.$i18n.mergeLocaleMessage('en', pageTranslations.en);       
    },
    methods:{
        getErrors(name, model) { // keep if we want to improve
          const error = false;
          if (!model.$dirty) return error;
          switch (name) {
            case "firstName":
              !model.required && errors.push("First name is required.");
              break;
            case "email":
              !model.email && errors.push("Must be valid e-mail");
              !model.required && errors.push("E-mail is required");
              break;
            default:
              break;
          }
          return error;
        },
        changeAddress: function(){
          this.isDirty = true;
          this.$emit("update:dirty", this.isDirty);
        }
    },


    components: {
        phone,
    },
}
</script>
<template>
    <div class="grid-pad minHeight BGImg">
        <h1 v-html="$t('canceled.title')"></h1>
        <!--Information selon Status-->
        <div v-if="status == 'Pending'" class="grid grid-pad mt-10"> 
            <h2 class="soustitre"  v-html="$t('canceled.treatment')"></h2>
            <v-row>   
                <v-col>
                    <v-progress-linear class="" color="rgb(var(--primary))" indeterminate striped height="8"></v-progress-linear>
                </v-col>
            </v-row>
        </div>
        <div v-if="status == 'Succeed'">
            <div class="grid grid-pad mt-10">
                <v-row>
                    <h2 v-html="$t('canceled.cancel')"></h2>
                </v-row>
                <v-row>
                    <div>
                        <v-btn class="mt-5 orderBtnStyle1" style="">
                            <div >   
                                <h2 @click="navigateToHome()"  class="" v-html="$t('canceled.home')"></h2>
                            </div>
                        </v-btn>
                        <v-btn class="mt-5 orderBtnStyle1" style="">
                            <div >   
                                <h2 @click="navigateToInvoice()"  class="" v-html="$t('canceled.invoice')"></h2>
                            </div>
                        </v-btn>
                    </div>

                </v-row>
            </div>

        </div>
        <div v-if="status == 'Failed'"> 
            <v-row>
                <h2 v-html="$t('canceled.text')"></h2>
            </v-row>
            <v-row>
                <div>

                    <v-btn class="mt-5 orderBtnStyle1" style="">
                        <div >   
                            <h2 @click="navigateToInvoice()"  class="" v-html="$t('canceled.tryAgain')"></h2>
                        </div>
                    </v-btn>
                </div>

            </v-row>
        </div>
    </div>
</template>

<style scoped>
    .minHeight { min-height: 500px;}
    .BGImg {background-image: url('../../../../public/Resources/images/nos-avantages.jpg'); background-position: bottom right;}
    .orderBtnStyle1{display: inline-block; height:50px !important; background-color: rgb(var(--primary)); border: 1px solid gray; border-radius: 10px; width:240px; margin: 0px 20px 0px 20px;}
    .orderBtnStyle1:hover {background-color: rgb(var(--secondary)); color: white;}
    @media handheld, only screen and (max-width: 960px) {
      h2{font-size: 18px;}
    }

</style>

<script>
import { apiECommerceExtension } from '@/mixins/apiECommerceExtension.js'
import pageTranslations from '../../../../src/assets/i18N/eCommerce/invoice.json';

export default {
    name: 'Canceled', 
    data: () => ({
        status:'Pending', //Succeed, Failed, Pending
        tried: 0,
        confirmed: false,
        param: null,
    }),
    mixins: [apiECommerceExtension],
    mounted() {
        this.$i18n.mergeLocaleMessage('fr', pageTranslations.fr);
        this.$i18n.mergeLocaleMessage('en', pageTranslations.en);
        this.param = this.$route.params.sid;
        this.transactionCompleted();
  },
  methods: {
    navigateToInvoice: function () { this.$router.push('/invoices/' + this.param); },
    navigateToHome: function () { this.$router.push('/Accueil'); },
    transactionCompleted: async function() {
        if(this.param){
            this.tried++;
            let confirmed = await this.PaypalCanceled(this.param);
            if(confirmed){this.status = 'Succeed';}
            else{
                if(this.tried < 2){this.transactionCompleted()} else {this.status = 'Failed';}
            }
        }else { this.status = 'Failed';}
    },
  },
  
  components: {
  },
}
</script>
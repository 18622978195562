<template>
    <div class="mb-10">
        <section class="storeGridpad2 mt-10">
            <v-row class="ma-0 d-flex flex-sm-row flex-column-reverse">
                <v-col class=" d-flex justify-sm-start justify-center align-center catTitle pa-0 catCol" @click="changeBox()">
                    <img src="/Resources/images/icon/browse.png" style="width: 20px; height: 20px; margin-right:10px"/>
                    <h2 class=" uppercase bold oswald2" style="font-size:22px; cursor: pointer;" v-html="$t('section.categories')"></h2>
                </v-col>

                <v-col class="d-flex justify-sm-end justify-center align-center searchCol pa-0">
                    <v-text-field id="onlineStore_SearchInput" name="onlineStore_SearchInput" v-model="searchItem" class="searchBox" density="compact" variant="outlined" :label="$t('section.search')" append-inner-icon="mdi-magnify" @click:append-inner="search()" @keydown.enter="search()"></v-text-field>
                </v-col>
                
            </v-row>
            <div v-if="this.CategorieBox" class="rel BoxCat">
                <div class="closeCategory"  @click="this.CategorieBox = false;">X</div>
                <v-row >                       
                    <v-col class="TitleCatOpen"  v-for="(item, i) in categories" :key="i">
                        <v-container class="categorie" >                
                            <h2 class="bold oswald2" style="display: inline-block;" @click="navigateToCategory(item.id)">{{item.name}}</h2>
                        </v-container>
                    </v-col>
                </v-row>
            </div>
            <v-row  v-if="CategorieName"><v-col><h2 class="categorieName oswald2">{{ this.CategorieName }} <sup @click="navigateSamePage()" class="deleteCat">x</sup></h2></v-col></v-row>
        </section>

        <section class="container productContainer custom-scrollbar storeGridpad">
            <v-row v-if="this.Products" class="scrollbar">
                <v-col v-for="(item, i) in Products" :key="i">
                    <v-container class="productBox"  @click="navigateTo(item)" style="box-shadow:0px 0px 10px black;"> <!--v-if="item.id != 3"  v-if="item.promo == false"-->
                        <v-row class="ma-0 d-flex flex-column">
                            <v-col  class="pa-0" style="position: relative;">
                                <div class="productHover" style="">
                                    <h2 class="txt-c oswald textProductHover"  v-html="$t('section.text')"></h2>
                                </div>
                                <div class="product" style="height: 400px;"><img v-if="item.image.url" :src="item.image.url" :truesrc="item.image.url" style="height:100%; width:100%; "/></div>
                            </v-col>
                            <v-col class="pa-0" >
                                <v-col cols="12" class="px-0 d-flex align-top justify-center" style="height:120px; overflow-y: hidden;"><h2 class="title txt-c uppercase bold oswald2" style="line-height: normal; ">{{item.title}}</h2></v-col>
                                <v-col cols="12" class="py-0"><p class="txt-j oswald textProduct" style="height: 100px; overflow-y: hidden;">{{item.description}}</p></v-col>
                                <v-col cols="12" class="pa-0">
                                    <v-row class="ma-0 ">
                                        <v-col class="d-flex align-center justify-start py-0"><p p class="f-primary bold uppercase oswald2" v-html="$t('section.price')"></p> </v-col>
                                        <v-col class="d-flex align-center justify-end py-0"><p class="f-primary bold uppercase oswald2" v-if="item.price != 0">{{item.price}}$</p><p p class="f-primary bold uppercase oswald2" v-else v-html="$t('section.demo')"></p></v-col>
                                    </v-row>
                                </v-col>
                            </v-col>
                
                        </v-row>
                    </v-container>
                </v-col>
            </v-row>
            <v-row v-if="this.Products.length == 0 && loading == false"><h2 class="soustitre" v-html="$t('section.noProduct')"></h2></v-row>
            <v-row v-if="loading == true">
                <div style="width: 75%; display: block; margin-left: auto !important; margin-right: auto !important;">
                    <h2 class="soustitre" v-html="$t('section.loading')"></h2>
                    <v-progress-linear color="rgb(var(--primary))" indeterminate striped height="8"></v-progress-linear>
                </div>
            </v-row>
        </section>
        <v-container>
            <v-row>
                <v-col cols="12">
                    <partialListNavigation v-if="range" class="" v-model:response="response" @more="more"  />
                </v-col>
            </v-row>
            
        </v-container>

    </div>
</template>

<style scoped>
    .title { font-size: clamp(1rem,1vw,1.5rem) !important; } 
    .storeGridpad {padding: 25px 25px 0px 25px;}
    .storeGridpad2 {padding: 0px 25px 0px 25px;}
    .TitleCat{color: rgb(var(--primary)); font-weight: 100; font-size: 24px; display: inline-block; cursor: pointer;}
    .TitleCatOpen{color: rgb(var(--primary)); font-weight: 100; font-size: 24px; display: inline-block; cursor: pointer;}
    .TitleCatOpen:hover{color: rgb(var(--secondary));}
    .TitleCat:hover {color: rgb(var(--secondary));}
    .BoxCat{height: auto; width: auto; max-width: 50%; position: absolute  ; z-index: 100; background-color: rgba(var(--secondary),0.8); padding: 15px !important;}
    .product {  height: auto; width: auto; position: relative; background-image: url('@@/Resources/images/Default.jpg'); background-position: center center; background-repeat: no-repeat; background-size: cover; display: block; margin: auto auto !important; cursor: pointer;}
    .categorie { min-width: 250px; overflow: hidden; height: auto; }
    .deleteCat {cursor: pointer;}
    .deleteCat:hover{color: rgb(var(--secondary));}
    .categorie h2 {  font-size: 18px;}
    .categorie:hover { cursor: pointer; color: white; }
    .categorieName { color: rgb(var(--primary)); font-size: 34px;}
    .closeCategory {float: right; color: rgb(var(--primary)); position: absolute; right: 10px; top: 5px; cursor: pointer;}
    .closeCategory:hover {color: #FFFFFF;}
    .productContainer {max-height: 1000px; min-height: 200px; overflow-y: scroll;}
    .productContainer::-webkit-scrollbar { width: 1px !important;}
    
    .custom-scrollbar { max-height: auto; overflow-y: scroll; scrollbar-width: thin; scrollbar-color: rgb(var(--primary)) rgba(var(--secondary),0.1); }
    .custom-scrollbar::-webkit-scrollbar-thumb { background-color: #000; border-radius: 5px; }
    .custom-scrollbar::-webkit-scrollbar-track { background-color: #f1f1f1; }
    /* Styles pour la barre de défilement - Firefox */
    @supports (scrollbar-color: auto) { .custom-scrollbar { scrollbar-color: rgb(var(--primary))  rgba(var(--secondary),0.1); scrollbar-width: thin; } }
    /* Styles pour la barre de défilement - Edge (Chromium) */
    @supports (-ms-overflow-style: none) { .custom-scrollbar { scrollbar-color: rgb(var(--primary)) rgba(var(--secondary),0.1); scrollbar-width: thin; } }
    .productBox {height: 100%; width: 100%; min-width: 425px; max-width: 450px; padding: 0px; cursor: pointer;}
    .productHover{position: absolute; background-color: rgba(var(--primary), 0.6); width: 100%; height: 100%; visibility: hidden; z-index: 100;}
    .textProductHover{position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%); width: 100%; color: white;}
    .productBox:hover .productHover{visibility: visible;}
    .searchBox {color: rgb(var(--secondary)); font-weight: 700; max-width:400px; min-width: 200px;}
    .searchCol {min-width:200px; float: left;}
    .catCol {max-width:450px; float: right;}
    h2 {font-size: 28px;}
    h5 {font-size: 20px;}
    .textProduct { line-height: normal; height:200px; overflow-y: hidden; font-size:clamp(0.75rem,1vw,0.75rem); color: rgba(var(--secondary), 0.7); }
    @media handheld, only screen and (max-width: 400px) {
        .BoxCat{max-width: 85% !important;}
        .catCol {max-width:350px;}
        .storeGridpad {padding: 25px 15px 0px 15px;}
    }
    
    @media handheld, only screen and (max-width: 600px) {
        h2 {font-size: 18px;}
        .textProduct{ font-size: 10px; height:100%; }
        .productBox { min-width: 250px; }
        .searchCol {min-width:200px;}

        .BoxCat{max-height: 100%; width: auto; max-width: 90%; padding: 0px !important;}

    }
    @media handheld, only screen and (max-width: 800px) {
        .searchBox {max-width:100%;}
    }
    @media handheld, only screen and (max-width: 960px) {}
    @media handheld, only screen and (max-width: 1279px) {}
</style>

<script>
import { apiECommerceExtension } from '@/mixins/apiECommerceExtension.js'
// import pageTranslations from '../../src/assets/i18N/onlineStore.json';
import pageTranslations from '@/assets/i18N/onlineStore.json';

import partialListNavigation from '@/components/partialListNavigation.vue'

export default {
  name: 'OnlineStore',
  mixins: [apiECommerceExtension],
  data: () => ({
    Products: [],
    categories:[],
    selectedCategories: 0,
    searchBox: false,
    searchItem: "",
    CategorieBox: false,
    CategorieName: null,
    CategorieSubtitle: "Les produits mentionnés ci-dessous sont des exemples de démonstration et sont exclusivement disponibles en magasin.",
    loading: false,
    range: null,
    response: null,
  }),
  beforeCreate: function () {
    this.$i18n.mergeLocaleMessage('fr', pageTranslations.fr);
    this.$i18n.mergeLocaleMessage('en', pageTranslations.en);
    },
  mounted: async function() {
    let id = this.$route.params.id;
    this.categories = await this.EcommerceCategoriesList();
    if(id){
        let found = false;
        this.categories.forEach(e => {
            if(e.id == id){ this.selectCategorie(e); found = true; }
        });
        if(found == false){this.navigateSamePage();}   
    }else{
        this.loading = true;
        this.Products = await this.EcommerceProductsList(this.range,this.callback);
        this.loading = false;
    }

  },
  methods:{
    navigateSamePage: function(){ window.location = window.location.origin + '/Boutique'; },
    navigateTo: function (item) {this.$router.push('/product/' + item.id);},
    navigateToCategory: function(id) { window.location = window.location.origin + '/Boutique/' + id; },
    changeBox: function(){ if (!this.CategorieBox) {this.CategorieBox = true; }else{this.CategorieBox = false;} },
    changeSearchBox: function(){ if (!this.searchBox) {this.searchBox = true; }else{this.searchBox = false;} },
    selectCategorie: async function(item) {
        this.selectedCategories = item.id;
        this.loading = true;
        let filter = {"categoryId": item.id};
        this.Products = await this.EcommerceProductsListWithFilter(filter,this.range,this.callback);
        this.CategorieName = item.name;
        this.CategorieBox = false;
        this.loading = false;
    },
    callback: function (response) {
        this.response = response;
        this.range = $App.getRangeHeader(response);
    },
    more: async function () {
        let id = this.$route.params.id;
        if(id){this.loading = true; let filter = {"categoryId": id}; this.Products = await this.EcommerceProductsListWithFilter(filter,this.range,this.callback);this.loading = false;} else {this.loading = true; this.Products = await this.EcommerceProductsList(this.range,this.callback); this.loading = false;}        
    },
    search: async function() {
        let filter = {"Keywords": this.searchItem};
        this.loading = true;
        this.Products = [];
        this.Products = await this.EcommerceProductsListWithFilter(filter,this.range,this.callback);
        if(this.Products) {this.loading = false;} 

    }
  },
  components: {
    partialListNavigation
  }
}
</script>

<template>
  <div>
    <!--Banner-->
    <section>
      <diapo/>
    </section>

    <!--Company-->
    <section class="gridpad mSection">
      <v-row>
        <v-col cols="12" class="txt-j">
          <h1 v-html="$t('company.title')" class="bold oswald2 d-flex justify-center justify-md-start"></h1>

          <p class="textSpacing"><span class='bold underline'>{{$t('company.teamTitle')}}</span></p>
          <p v-html="$t('company.teamText')" class="textSpacing"></p>
          <p class="textSpacing txt-r"><span class='bold underline'>{{$t('company.mission')}}</span></p>
          <p v-html="$t('company.missionText')" class="textSpacing"></p>
          <h4 v-html="$t('company.slogan')" class="txt-c bold mt-8"></h4>
          <h4 v-html="$t('company.slogan2')" class="txt-c bold"></h4>
        </v-col>
      </v-row>
    </section>

    <!--Solutions-->
    <section class="bgBlack">
      <div class=" soBg gridpad">
        <v-row>
          <v-col cols="12" class="d-flex justify-center"><h3 v-html="$t('solutions.title')" class="uppercase ital bold f-primary txt-c"></h3></v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4"></v-col>
          <v-col cols="12" md="4"><p v-html="$t('solutions.col2up')" class="soCol2 bold"></p></v-col>
          <v-col cols="12" md="4"></v-col>
        </v-row>
        <v-row>
          <v-col  cols="12" md="4" class="d-flex align-center justify-center">
            <p v-html="$t('solutions.col1')" class="soCol1 bold"></p>
          </v-col>
          <v-col  cols="12" md="4" class="d-md-flex align-center justify-center d-none" >
            <div class="d-flex align-center justify-center"><img src="../../public/Resources/images/logo/Habitat3D.png" style="width:100%"/></div>
          </v-col>
          <v-col cols="12" md="4" class="d-flex align-center justify-center">
            <p v-html="$t('solutions.col3')" class="soCol3 bold"></p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4"></v-col>
          <v-col  cols="12" md="4" class="d-flex align-end justify-end txt-c">
            <div class="d-flex align-end justify-end"><p v-html="$t('solutions.col2down')" class="soCol2 bold "></p></div>
          </v-col>
          <v-col cols="12" md="4"></v-col>
        </v-row>
      </div>
    </section>

    <!--Plans-->
    <section class="gridpad">
      <v-row>
        <v-col cols="12" class="planText">
          <h3 class="txt-c textSpacing"><span class='bold'>{{$t('plan.title')}}</span></h3>
          <p v-html="$t('plan.text')"></p>
          <h4 class="txt-c textSpacing2"><span class='bold'>{{$t('plan.slogan')}}</span></h4>          
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div class="d-flex align-center justify-center "><img src="../../public/Resources/images/house_plan.jpg" class="qualityImg"/></div>
        </v-col>
      </v-row>
    </section>

    <!--equipe expert-->
    <section >
      <div class="teamBg d-flex align-center">
        <div class="teamBgBlack ">
          <v-row>
            <v-col cols=12 class="d-flex justify-center align-center">
              <div class="d-flex align-center">
                <h3 v-html="$t('team.title')" class="teamLogo oswald2 txt-c "></h3>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>

      </section>

    <!--Quality-->
    <section class="bgQuality ">
      <div class="mSection2" >
        <v-row>
          <v-col cols="12" class="d-flex flex-column align-start">
            <h3><span class='bold uppercase'>{{$t('quality.title')}}</span></h3>
            <p v-html="$t('quality.text')" class="quaPad txt-j"></p>
            <h2 v-html="$t('quality.text2')" class=" QuaText"></h2>
          </v-col>
        </v-row>
      </div>
    </section>

    <!--Design-->
    <section class=" designBg mt-10">
      <div class=" bgBlack gridpad">
        <v-row>
          <v-col cols="12" class="d-flex justify-center">
            <h3 class="f-white"><span class='bold uppercase'>{{$t('design.title')}}</span></h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6" class="d-flex justify-md-end justify-center pa-0">
            <img src="../../public/Resources/images/img_accueil_1.jpg" />
          </v-col>
          <v-col cols="12" md="6" class="d-flex justify-md-start justify-center pa-0">
            <img src="../../public/Resources/images/img_accueil_2.jpg" />
          </v-col>
          <v-col cols="12" md="6" class="d-flex justify-md-end justify-center pa-0">
            <img src="../../public/Resources/images/img_accueil_3.jpg" />
          </v-col>
          <v-col cols="12" md="6" class="d-flex justify-md-start justify-center pa-0">
            <img src="../../public/Resources/images/img_accueil_4.jpg" />
          </v-col>
        </v-row>
      </div>
    </section>

    <!--Isolation-->
    <section class="gridpad mSection">
      <v-row>
        <v-col cols="12" class="d-flex justify-md-end justify-center">
          <h3><span class='bold'>{{$t('insulation.title')}}</span></h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="planText">
          <p v-html="$t('insulation.text')"></p>
        </v-col>
        <v-col cols="12" class="d-flex justify-center">
          <p><span class='bold'>{{$t('insulation.text2')}}</span></p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div v-if="$i18n.locale == 'fr'" class="d-flex align-center justify-center "><img src="../../public/Resources/images/fr/insulation_house.jpg" class="qualityImg"/></div>
          <div v-if="$i18n.locale == 'en'" class="d-flex align-center justify-center "><img src="../../public/Resources/images/en/insulation_house.jpg" class="qualityImg"/></div>
        </v-col>
      </v-row>
    </section>


  </div>
</template>

<style scoped>
.textSpacing {margin: 15px 0px;}
.textSpacing2 {margin: 30px 0px;}
.mSection {margin: 15px 0px 45px 0px;}
.mSection2 {margin: 115px calc(12.5% + 100px) 145px calc(12.5% + 100px);}
.bgBlack {
        background-color: rgba(0,0,0,0.9); padding:25px 0px 60px 0px;
    }


/*#region company*/

/*#endregion*/ 

/*#region solutions*/
.soPad {padding: 0px 12.5% 45px 12.5%;}
.soCol1 {text-align: left; color: rgb(var(--forth)) !important;}
.soCol2 {text-align: center; color: rgb(var(--forth)) !important;}
.soCol3 {text-align: right; color: rgb(var(--forth)) !important;}
.soBg {
  width: 100%; height: 100%; 
  background-image: url("../../public/Resources/images/background_bs.png");
  background-position: center;
  background-repeat: no-repeat; 
  background-size: cover;
}
/*#endregion*/ 

/*#region plans*/
.planText { text-align: justify;}
.qualityImg{ width:50%;}
/*#endregion*/ 

.quaPad {padding:75px 0px 75px 0px;}
.QuaText {line-height: 30px; text-align: center;}
.bgQuality {
  background-image: url("../../public/Resources/images/quality_backgroud.png");
  background-position: center;
  background-repeat: no-repeat; 
  background-size:120%;
  padding: 100px 0px 90px 0px;
}
.designBg {
  background-image: url("../../public/Resources/images/structure_design.jpg");
  background-position: center;
  background-repeat: no-repeat; 
  background-size: cover;
}
.teamBg {position: relative; height: 800px;}
.teamBg::before {
    content: ' '; 
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background-image: url('../../public/Resources/images/img_expert_team2.png');
    background-position: bottom;
    background-repeat: no-repeat; 
    background-size: cover; 
}
/*
.teamBg {
  width:100%;
  background-image: url("../../public/Resources/images//img_expert_team2.png");
  opacity: 0.7;
  background-position: center;
  background-repeat: no-repeat; 
  background-size: contain;
}*/
.teamBgBlack {
    position:relative ; background-color: rgba(0,0,0,0.8); z-index:1000; width:100%; margin: 13vw 0px 13vw 0px;
}
.teamLogo {
    color: red;
    text-shadow: 1px -6px 5px #000000;
    font-size: calc(7vw) !important;
    line-height: 1.25;
  }
@media handheld, only screen and (max-width: 450px) {}
    @media handheld, only screen and (max-width: 600px) {
      .quaPad {padding:0px 0px 15px 0px!important;}
      .bgQuality {
        background-position-y: 22%;
        background-size:120%;
        padding: 0px 0px 0px 0px;
      }
      .QuaText {line-height: 30px; font-size: 24px; text-align:justify}
      .teamBg{height: 200px;}
    }
    @media handheld, only screen and (max-width: 960px) {
      .soCol1 {text-align: center;}
      .soCol3 {text-align: center;}
      .qualityImg{ width:90%;}
      .mSection2 {margin: 0px 15px 0px 15px;}
      .QuaText {line-height: 30px; font-size: 24px;}
      .quaPad {padding:30px 0px 39px 0px;}
      .teamBg{height: 400px;}
    }
    @media handheld, only screen and (max-width: 1030px) {
    }
    @media handheld, only screen and (max-width: 1279px) {
      .bgQuality {
        background-position-y: 22%;
        background-size:120%;
        padding: 0px 0px 0px 0px;
      }
      .teamBg{height: 600px;}
    }
    @media handheld, only screen and (max-width: 1378px) {
    }

</style>

<script>
import pageTranslations from '../../src/assets/i18N/home.json';
import diapo from '../components/diapo.vue'

export default {
  name: 'Home',
  data: () => ({
  }),
  beforeCreate: function () {
        let lang = localStorage.getItem("Lang");
        if(lang){this.$i18n.locale = lang;} else { this.$i18n.locale = 'fr'; }
 
        this.$i18n.mergeLocaleMessage('fr', pageTranslations.fr);
        this.$i18n.mergeLocaleMessage('en', pageTranslations.en);
    },
  mounted() {

  },
  methods:{
  },
  components: {
    diapo
  }
}
</script>

<template>
    <div>
      <!--Banner-->
      <section class="mNone">
        <v-row>
          <v-col cols=12 class="banner">
              <div class="bgBanner"></div>
              <div class="d-flex align-center justify-center logoBanner"><img src="../../public/Resources/images/logo/porteGarage3D.jpg" style="width:400px;"/></div>
          </v-col>
        </v-row>
      </section>

      <!--GarageDoor-->
      <section class="gridpad mSection">
        <v-row>
          <v-col cols="12" class="d-flex justify-start">
            <h1 class="oswald"><span class='bold uppercase'>{{$t('garageDoor.title')}}</span></h1>
          </v-col>
        </v-row>
        <v-row class="txt-j">
          <v-col cols="12" class="d-flex align-center">
            <p v-html="$t('garageDoor.text')"></p>
            <img class="caFlag" src="../../public/Resources/images/icon/canadaFlag.png" />
          </v-col>
          <v-col cols="12">
            <p v-html="$t('garageDoor.text2')"></p>
          </v-col>
        </v-row>
      </section>

      <!--MegaDoor-->
      <section class="megaDoorBg d-flex align-center">
        <v-row>
          <v-col cols=12 class="d-flex justify-center">
              <h3 class="megaDoorLogo oswald2"><span class='bold uppercase'>{{$t('megaDoor.title')}}</span></h3>
          </v-col>
        </v-row>
      </section>

      <!--Aviation-->
      <section class="gridpad mSection txt-j">
        <v-row>
          <v-col cols="12">
            <h5><span class='bold underline'>{{$t('megaDoor.aviation')}}</span></h5>
          </v-col>
          <v-col cols="12">
            <p v-html="$t('megaDoor.text')"></p>
          </v-col>
          <v-col cols="12">
            <p v-html="$t('megaDoor.text2')"></p>
          </v-col>
          <v-col cols="12" class="d-flex justify-center">
            <div>
              <p v-html="$t('megaDoor.linkA')" class="inlineTxt"></p>
              <a href="/Resources/documents/Mega_door_Garage_Doors_3D.pdf" v-html="$t('megaDoor.linkB')" class="inlineTxt link" target="_blank"></a>
              <p v-html="$t('megaDoor.linkC')" class="inlineTxt"></p>
            </div>
          </v-col>
        </v-row>
      </section>

      <!--AviationImg-->
      <section class="mSection" >
        <div class=" bgBlack2">
          <v-row class="ma-0">
            <v-col class="d-flex justify-md-end justify-center pa-0 imgScale">
              <img src="../../public/Resources/images/Agricolte1.jpg" class="avImg"/>
            </v-col>
            <v-col class="d-flex justify-center pa-0 imgScale">
              <img src="../../public/Resources/images/Agricolte2_1.jpg" class="avImg"/>
            </v-col>
            <v-col class="d-flex justify-md-start justify-center pa-0 imgScale">
              <img src="../../public/Resources/images/Agricolte3_1.jpg" class="avImg"/>
            </v-col>
          </v-row>
        </div>
      </section>

      <!--Specification-->
      <section class="gridpad">
        <v-row>
          <v-col cols="12" class="d-flex justify-start">
            <h3><span class='bold'>{{$t('species.title')}}</span></h3>
          </v-col>
          <v-col cols="12" class="d-flex justify-start">
            <h4><span class='bold'>{{$t('species.size')}}</span></h4>
          </v-col>
          <v-col cols="12" class="d-flex justify-end">
            <h5><span class='bold underline'>{{$t('species.sectors')}}</span></h5>
          </v-col>
          <v-col cols="12" style="min-width: 100%;" v-if="$i18n.locale == 'en'" class="d-flex justify-center"><img src="../../public/Resources/images/en/Specification.jpg" class="specImg"/></v-col>
          <v-col cols="12" style="min-width: 100%;" v-if="$i18n.locale == 'fr'" class="d-flex justify-center"><img src="../../public/Resources/images/fr/Specification.jpg" class="specImg"/></v-col>
        </v-row>
        <v-row>
          <v-col cols="7" sm="6" class="d-flex justify-md-start justify-center align-center">
            <h5 class="oswald2"><span class='bold uppercase'>{{$t('species.color')}}</span></h5>
          </v-col>
          <v-col cols="5" sm="6" class="d-flex justify-md-center justify-center align-center"><img src="../../public/Resources/images/DoorColor.png" class="colorImg"/></v-col>
        </v-row>
      </section>

      <!--Installation-->
      <section class="gridpad txt-j mSection">
        <v-row>
          <v-col cols="12" class="d-flex justify-center">
            <h3><span class='bold'>{{$t('species.installation')}}</span></h3>
          </v-col>
          <v-col cols="12" md="6" class="d-flex align-center order-2 order-md-1 "><p v-html="$t('species.text')" class="installGridpad "></p></v-col>
          <v-col cols="12" md="6" v-if="$i18n.locale == 'en'" class="d-flex align-center justify-center order-1 order-md-2"><img src="../../public/Resources/images/en/HandymanMeeter.png" class="meeterImg"/></v-col>
          <v-col cols="12" md="6" v-if="$i18n.locale == 'fr'" class="d-flex align-center justify-center order-1 order-md-2"><img src="../../public/Resources/images/fr/HandymanMeeter.png" class="meeterImg"/></v-col>
        </v-row>
      </section>

      <!--Easy to install-->
      <section class="gridpad mSection">
        <v-row>
          <v-col cols="12" class="d-flex align-center justify-center">
            <h4 class="sloganSpecies oswald2 txt-c"><span class='bold uppercase'>{{$t('species.slogan')}}</span></h4>
          </v-col>
        </v-row>
        <v-row>
          <v-col v-if="Step" v-for="(item, i) in Step" :key="i" >
            <v-container class="pa-0 stepContainer">
              <div><p class="stepTitle v-primary" v-html="$t(item.title)"></p></div>
              <div class="stepBoxImg"><img class="stepImg" :src="item.img"/></div>
              <div class="d-flex align-center stepBoxText pa-0">
                <h3 class="txt-j stepText mt-4">{{ $t(item.txt)}} <br/><br/> {{ $t(item.txt2)}}</h3>
              </div>
            </v-container>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="d-flex justify-center">
            <div>
              <p v-html="$t('species.linkA')" class="inlineTxt"></p>
              <a href="Resources/documents/guide_installation_porte_de_garage_3D_2019.pdf" v-html="$t('species.linkB')" class="inlineTxt link" target="_blank"></a>
              <p v-html="$t('species.linkC')" class="inlineTxt"></p>
            </div>
          </v-col>
        </v-row>
      </section>

      <!--Video and images-->
      <section class="">
        <div class=" bgBlack2">
          <v-row class="ma-0">
            <v-col style="min-width: 350px; max-height: 450px;" class="d-flex justify-md-end justify-center pa-0 imgScale">
              <img v-if="$i18n.locale == 'en'" src="../../public/Resources/images/en/imagePorte.jpg" class="avImg"/>
              <img v-if="$i18n.locale == 'fr'" src="../../public/Resources/images/fr/imagePorte.jpg" class="avImg"/>
            </v-col>
            <v-col style="min-width: 350px; max-height: 450px;"  class="d-flex justify-center pa-0">
              
              <video controls style="height: 100%; width: 100%;">
                <source src="Resources/videos/videoPortedegarage3D.mov" type="video/mp4">
                Your browser does not support HTML video.
              </video>
              
            </v-col>
            <v-col style="min-width: 350px; max-height: 450px;" class="d-flex justify-md-start justify-center pa-0">
              <img src="../../public/Resources/images/imagePorte2_1.png" class="avImg"/>
            </v-col>
          </v-row>
        </div>
      </section>

      <!--Contact Us-->
      <section id="Form" class="bgForm ">
        <div class="gridpad bgWhite pt-8 pb-8">
          <v-row>
            <v-col cols="12">
              <h3><span class='bold'>{{$t('form.title')}}</span></h3>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <form  ref="form">
                <!--Form-->
                <v-row>
                  <v-col cols="12" class="px-5">
                    <v-row class="">

                      <!--firstName-->
                      <v-col cols="12" md="6" class="pa-0">
                        <v-col cols="12" class="">
                          <p class="bold" v-if="!error.firstName" style="font-size: 14px;">
                            <span class='bold'>{{$t('form.firstName')}}</span>
                          </p>
                          <p class="bold error" v-if="error.firstName" style="font-size: 14px;">
                            <span class='bold'>{{$t('form.firstName')}}</span>
                          </p>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field @change="error.firstName = false" id="firstName" name="firstName" type="text" v-model="item.firstName" bg-color="red-lighten-2" clearable required/>
                          <p class="errorText" v-if="error.firstName" style="font-size: 14px; margin-top: -25px;">** Un nom est requis**</p>
                        </v-col>
                      </v-col>

                      <!--lastName-->
                      <v-col cols="12" md="6" class="pa-0">
                        <v-col cols="12" class="">
                          <p class="bold" v-if="!error.lastName" style="font-size: 14px;">
                            <span class='bold'>{{$t('form.lastName')}}</span>
                          </p>
                          <p class="bold error" v-if="error.lastName" style="font-size: 14px;">
                            <span class='bold'>{{$t('form.lastName')}}</span>
                          </p>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field @change="error.lastName = false" id="lastName" name="lastName" type="text" v-model="item.lastName" bg-color="red-lighten-2" clearable required/>
                          <p class="errorText" v-if="error.lastName" style="font-size: 14px; margin-top: -25px;">** Un nom est requis**</p>
                        </v-col>
                      </v-col>

                      <!--phone-->
                      <v-col cols="12" md="6" class="pa-0">
                        <v-col cols="12">
                          <p class="bold" v-if="!error.phone" style="font-size: 14px;">
                            <span class='bold'>{{$t('form.phone')}}</span>
                          </p>
                          <p class="bold error" v-if="error.phone" style="font-size: 14px;">
                            <span class='bold'>{{$t('form.phone')}}</span>
                          </p>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field id="Telephone" name="Telephone" type="text" bg-color="red-lighten-2" label="xxx xxx-xxxx" v-model="item.phone"  clearable required/>
                          <p class="errorText" v-if="error.phone" style="font-size: 14px; margin-top: -25px;">Un téléphone est requis ou est incorrect**</p>
                        </v-col>
                      </v-col>

                      <!--email-->
                      <v-col cols="12" md="6" class="pa-0">
                        <v-col cols="12"><p class="bold" style="font-size: 14px;">
                          <span class='bold'>{{$t('form.mail')}}</span>
                        </p></v-col>
                        <v-col cols="12"><v-text-field id="Courriel" name="Courriel" type="text" bg-color="red-lighten-2" v-model="item.email" clearable required class="font-weight-black"/></v-col>
                      </v-col>

                      <!--height-->
                      <v-col cols="12" md="6" class="pa-0">
                        <v-col cols="12"><p class="bold" style="font-size: 14px;">
                          <span class='bold'>{{$t('form.height')}}</span>
                        </p></v-col>
                        <v-col cols="12"><v-text-field id="Height" name="Height" type="text" v-model="item.height" bg-color="red-lighten-2" clearable required/></v-col>
                      </v-col>
                          
                      <!--size-->
                      <v-col cols="12" md="6" class="pa-0"> 
                        <v-col cols="12"><p class="bold" style="font-size: 14px;">
                          <span class='bold'>{{$t('form.sizeDoor')}}</span>
                        </p></v-col>
                        <v-col cols="12"><v-select id="size" name="size" type="text" clearable chips v-model="item.size" bg-color="red-lighten-2" :items="[$t('form.size5'), $t('form.size6'), $t('form.size7'), $t('form.size8'), $t('form.size9'), $t('form.size10'), $t('form.size12to18'), $t('form.size20to24'), $t('form.size26to42')]" multiple required/></v-col>
                      </v-col>

                    </v-row>
                  </v-col>

                  <v-col cols="12" class="px-5">
                     <!--address-->
                     <v-row>
                      <v-col cols="12"><p class="bold" style="font-size: 14px;">
                        <span class='bold'>{{$t('form.add')}}</span>
                      </p></v-col>
                      <v-col cols="12"><v-text-field id="Ville" name="Ville" type="text" v-model="item.address" bg-color="red-lighten-2" clearable required/></v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" class="px-5">
                     <!--comment-->
                     <v-row>
                      <v-col cols="12"><p class="bold" style="font-size: 14px;">
                        <span class='bold'>{{$t('form.comment')}}</span>
                      </p></v-col>
                      <v-col cols="12"><v-textarea clearable required no-resize id="description" name="description" v-model="item.Message" bg-color="red-lighten-2" label="Description"  rows="7" row-height="45" single-line :counter="520" maxlength="520"/></v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <!--accept form-->
                <v-row class="d-flex flex-md-row flex-column ma-0">
                  <v-col cols="12" md="9" class="d-flex align-center justify-center justify-md-start">
                    <p v-if="this.Dialog == 'error'" class="sendError" v-html="$t('form.error')"></p>
                  </v-col>
                  <v-col cols="12" md="3" class="d-flex align-center justify-md-end justify-center">
                    <v-btn v-if="this.Dialog != 'send'" @click="this.SendForm();" class="button" style="margin-right:15px;">
                      <span class="buttonText bold uppercase" v-if="this.Dialog == 'notSend' || this.Dialog == 'error'">
                        {{$t('form.send')}}
                      </span>
                      <span class="buttonText bold uppercase" v-if="this.Dialog == 'pending'">{{$t('form.wait')}}</span>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" class="pa-0">
                    <h3 v-if="this.Dialog == 'send'" class="f-primary " style="text-align: center; font-size: 2em;">
                      <span class='bold uppercase'>{{$t('form.complete')}}</span>
                    </h3>
                  </v-col>
                </v-row>
              </form>
            </v-col>
          </v-row>
        </div>
      </section>

      <!--Why Us-->
      <section class="gridpad mSection">
        <v-row>
          <v-col cols="12" class="d-flex justify-start">
            <h3>
              <span class='bold'>{{$t('whyUs.title')}}</span>
            </h3>
          </v-col>
        </v-row>
        <v-row class="txt-j">
          <v-col cols="12" class="d-flex align-center">
            <p v-html="$t('whyUs.text')"></p>
          </v-col>
          <v-col cols="12" class="mt-8">
            <p v-html="$t('whyUs.text2')"></p>
          </v-col>
        </v-row>
      </section>
    </div>
  </template>
  <!-- <i18n src="../assets/i18N/garagedoor.json"></i18n> -->
  
<style scoped>
.caFlag {width:75px; margin: 0px 0px 0px 10px;}
.textSpacing {margin: 15px 0px;}
.textSpacing2 {margin: 30px 0px;}
.mSection {margin: 15px 0px 45px 0px;}
.inlineTxt {display: inline;}
.bgWhite {
        background-color: rgba(255,255,255,0.8); z-index:1000;
    }
.bgBlack {
        background-color: rgba(0,0,0,0.9); padding:25px 0px 60px 0px;
 }
 .bgBlack2 {
        background-color: rgba(0,0,0,0.9); padding:0px 0px 0px 0px;
 }

/*#region Banner*/ 
.banner { position: relative; height:600px;}
.bgBanner {
  width:100%; height:100%;
  background-image: url("../../public/Resources/images/BgLogo3D.jpg");
  background-position: center;
  background-position-y:75%;
  background-repeat: no-repeat; 
  background-size: 110%;
}
.logoBanner {
  position:absolute; 
  width:500px; height:150px; 
  right:0px;bottom:9%; 
  background-color: rgba(255,255,255,0.7);
}
/*#endregion*/

/*#region MegaDoor*/
  .megaDoorBg {
    width:100%; height:400px; 
    background-image: url("../../public/Resources/images/MegaDoorbg.png");
    opacity: 0.7;
    background-position: center;
    background-position-y: 25%;
    background-repeat: no-repeat; 
    background-size: 100%;
  }
  .megaDoorLogo {
    color: rgb(var(--primary));
    text-shadow: 1px -6px 5px #000000;
    font-size: calc(50px + 4vw) !important;
  }
/*#endregion*/

/*#region Aviation*/

/*#endregion*/

/*#region AviationImg*/
.avImg {width:100%; height: auto;}
/*#endregion*/

/*#region Specification*/
.colorImg {width:250px;}
.specImg {width: 65%; height: auto;}
/*#endregion*/

/*#region Installation*/
.meeterImg {width:75%; min-width:420px;}
.installGridpad {padding: 0px 12.5%}
/*#endregion*/

/*#region Easy to install*/
.sloganSpecies {
  font-size: 56px; 
  line-height: 75px;
  color: rgb(247,152,155); 
  text-shadow: 0px -3px 10px black;
  padding: 15px 0px 15px 0px;
}
.stepContainer {width: 320px;}
.stepTitle {padding-left:5px; color:white; min-height: 30px;}
.stepText {font-size: 15px !important; line-height: 18px;}
.stepBoxText {min-height:160px;}
.stepImg {object-fit: contain; max-width: 100%;}
.stepBoxImg {height:190px; overflow-y: hidden;}
/*#endregion*/

/*#region Video and images*/

/*#endregion*/

/*#region Contact Us*/
.bgForm{
  background-image: url("../../public/Resources/images/formBG.jpg");
  background-position: center;
  background-repeat: no-repeat; 
  background-size: cover;
}

.sendError {color: red; margin-left:15px; font-size: 16px;}
/*#endregion*/

/*#region Why Us*/

/*#endregion*/
  .imgScale {min-width: 350px; max-height: 450px;}

    @media handheld, only screen and (max-width: 500px) {}

    @media handheld, only screen and (max-width: 600px) {
      .banner { position: relative; height:175px !important;}
      .bgBanner {
        width:100%; height:100%;
        background-image: url("../../public/Resources/images/BgLogo3D.jpg");
        background-position: center;
        background-position-y:75%;
        background-repeat: no-repeat; 
        background-size: 150%;
      }
      .logoBanner {
        position:absolute; 
        width:500px; height:100%; 
        right:0px;bottom:0; 
        background-color: rgba(255,255,255,0.7);
      }
      .megaDoorLogo {
        font-size: calc(36px + 4vw) !important;
      }
      .specImg {width: 100%; height: auto; max-height: 300px;}
      .imgScale { max-height: 300px;}
    }
    @media handheld, only screen and (max-width: 650px) {
      .colorImg {width:180px;}
      .sendError {color: red; margin-left:15px; font-size: 14px !important;}
    }
    @media handheld, only screen and (max-width: 960px) {
      .banner { position: relative; height:325px;}
      .bgBanner {
        width:100%; height:100%;
        background-image: url("../../public/Resources/images/BgLogo3D.jpg");
        background-position: center;
        background-position-y:75%;
        background-repeat: no-repeat; 
        background-size: 150%;
      }
      .megaDoorBg {
        height:250px; 
        background-position: center;
        background-position-y: 25%;
        background-size: 150%;
      }
      .mNone { display: none;}
      .avImg {width:100%;}
      .specImg {width: 100%;}
    }
      
    @media handheld, only screen and (max-width: 1030px) {
    }
    @media handheld, only screen and (max-width: 1279px) {
    }
    @media handheld, only screen and (max-width: 1378px) {
      .installGridpad {padding: 0px 0px !important;}
    }
</style>

  <script>
  import pageTranslations from '../../src/assets/i18N/garagedoor.json';
  
  export default {
    name: 'garageDoor',
    data: () => ({
      cart:null,
      item: {
        firstName: null,
        lastName: null,
        email: null,
        phone: null,
        height: null,
        address: null,
        size: '10 pi / ft',
        Message: null
      },
      Dialog: "notSend", //"error, send, notSend, pending"
      error:{
        firstName: false,
        phone: false
      },
      key: "", //need to add key
      showForm: true,
      Step: [
        {
          img: 'Resources/images/1.jpg',
          title: 'species.step1Title',
          txt: 'species.step1Text',
          txt2: ''
        },
        {
          img: 'Resources/images/2.jpg',
          title: 'species.step2Title',
          txt: 'species.step2Text',
          txt2: 'species.step2Text2'
        },
        {
          img: 'Resources/images/7.jpg',
          title: 'species.step3Title',
          txt: 'species.step3Text',
          txt2: ''
        },
        {
          img: 'Resources/images/8.jpg',
          title: 'species.step4Title',
          txt: 'species.step4Text',
          txt2: ''
        },
      ],
    }),
    beforeCreate: function () {
            this.$i18n.mergeLocaleMessage('fr', pageTranslations.fr);
            this.$i18n.mergeLocaleMessage('en', pageTranslations.en);
        },
    created: function () {
            //$App.event.$on("app_hotkey_ctrlS", this.onCtrlS);
        },
    mounted() {
    },
    methods: {
      validation: async function(){
        let valid = true;
        let errorFlag = {firstName: false, phone: false};
        if(this.item.firstName == null || this.item.firstName.length == 0){ valid = false; errorFlag.firstName = true; }
        if(this.item.phone == null || this.item.firstName.length == 0 || this.item.phone.length > 12){valid = false; errorFlag.phone = true; }
        this.error = errorFlag;
        return valid;
    },
      login: async function () {

        let api = 'https://abstracxion.wx3data.com/security/login/Habitat3D'; // need Tenant Habitat3D
        let data = null;
        try {
            let response = await fetch(api, {
                method: 'PUT', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: 'follow', // manual, *follow, error
                referrerPolicy: 'no-referrer', // no-referrer, *client
                body: JSON.stringify({LoginName: "", password: ""}) // body data type must match "Content-Type" header
            })
            if (await response.ok) {
                if (await response.status !== 204) {
                    let data = await response.text();
                    return data
                }
            } else {
                let msg = await response.text();
                $App.event.$emit("app_error", msg);
            };
                
        } catch (error) {
            console.log(error);
        }
    },
      SendForm: async function (){
      this.Dialog = "pending";
      let valid = await this.validation();
      if(valid == true){
        let formData = new FormData(this.$refs.form);
        /*
        setTimeout(() => {
          this.Dialog = "send";
        }, 3000);*/ 

        let api = 'https://abstracxion.wx3data.com/eCommerce/Forms/ContactUs/sendByEmail';
        try {
            let token = await this.login();

            // ** ATTENTION need to add bearer token...
            let response = await fetch(api, {
                method: 'POST',
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Authorization': 'Bearer ' + token
                },
                redirect: 'follow', // manual, *follow, error
                referrerPolicy: 'no-referrer', // no-referrer, *client
                body: formData
            });

            if (await response.ok) {
                if (await response.status !== 204) {
                  this.Dialog = "send";
                }
            } else {
              this.Dialog = "error";
            };

        } catch (error) {

        }
      }else { this.Dialog = 'error'; }

    },
    },
    components: {
    }
  }
  </script>
<template>
    <div>
      <!--Welcome banner-->
      <section>
        <v-row>
          <v-col cols=12 style="position: relative;">
              <img src="../../public/Resources/images/banner/BG_concept_top.jpg" style="width:100%"/>
              <h1 v-html="$t('welcome.title')" class="f-white oswald3 welcomeTitle" ></h1>
          </v-col>
        </v-row>
      </section>

      <!--Kitchen-->
      <section class="gridpad mSection txt-j">
        <v-row>
          <v-col cols="12" class="d-flex justify-start justify-sm-end"><h3 v-html="$t('kitchen.title')"></h3></v-col>
        </v-row>
        <v-row>
          <v-col cols="12"><h4 v-html="$t('kitchen.modern')" class="d-flex justify-sm-start justify-center"></h4></v-col>
          <v-col cols="12" md="6" class="d-flex align-center order-1"><p v-html="$t('kitchen.modernText')" style=""></p></v-col>
          <v-col cols="12" md="6" class="d-flex align-center order-3 order-md-2"><div class="kitchenImg1"></div></v-col>
          <v-col cols="12" class="order2 order-md-3"><p v-html="$t('kitchen.modernText2')"></p></v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="d-flex justify-sm-end justify-center"><h4 v-html="$t('kitchen.innovative')"></h4></v-col>
          <v-col cols="12" md="6" class="d-flex align-center order-2 order-md-1"><div class="kitchenImg2"></div></v-col>
          <v-col cols="12" md="6" class="d-flex align-center order-1 order-md-2"><p v-html="$t('kitchen.innovativeText')" style=""></p></v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class=""><h4 v-html="$t('kitchen.luxurious')" class="d-flex justify-sm-start justify-center"></h4></v-col>
          <v-col cols="12" md="6" class="d-flex align-center"><p v-html="$t('kitchen.luxuriousText')" style=""></p></v-col>
          <v-col cols="12" md="6" class="d-flex align-center"><div class="kitchenImg3"></div></v-col>
        </v-row>
      </section>

      <!--Bedroom-->
      <section class="bgBedroom">
        <v-row class="ma-0">
          <v-col cols=12 class="pa-0" style="position: relative;">
              <img src="../../public/Resources/images/BG_bedroom.png" style="width:100%; height:100%;"/>
              <h3 v-html="$t('bedroom.title')" class="f-white oswald3 bedTitle"></h3>
            </v-col>
        </v-row>
        <div class="gridpad">
          <v-row class="ma-0">
            <v-col cols="6" class="d-flex justify-end pa-0">
              <img src="../../public/Resources/images/imgbedroom1.jpg" class="bedImg"/>
            </v-col>
            <v-col cols="6" class="d-flex justify-start pa-0">
              <img src="../../public/Resources/images/imgbedroom2.png" class="bedImg"/>
            </v-col>
            <v-col cols="6" class="d-flex justify-end pa-0">
              <img src="../../public/Resources/images/imgbedroom3.png" class="bedImg"/>
            </v-col>
            <v-col cols="6" class="d-flex justify-start pa-0">
              <img src="../../public/Resources/images/imgbedroom4.png" class="bedImg"/>
            </v-col>
            <v-col cols="6" class="d-flex justify-end pa-0">
              <img src="../../public/Resources/images/imgwardrobe1.png" class="bedImg"/>
            </v-col>
            <v-col cols="6" class="d-flex justify-start pa-0">
              <img src="../../public/Resources/images/imgwardrobe2.png" class="bedImg"/>
            </v-col>
          </v-row>
        </div>
      </section>

      <!--LivingRoom-->
      <section class="gridpad mSection txt-j">
        <v-row>
          <v-col cols="12" class="d-flex justify-start"><h3 v-html="$t('livingRoom.title')"></h3></v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="d-flex justify-sm-start justify-center"><h4 v-html="$t('livingRoom.elegant')"></h4></v-col>
          <v-col cols="12" md="6" class="d-flex align-center"><p v-html="$t('livingRoom.elegantText')"></p></v-col>
          <v-col cols="12" md="6" class="d-flex align-center"><div class="livingImg1"></div></v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="d-flex justify-sm-end justify-center"><h4 v-html="$t('livingRoom.distinguish')"></h4></v-col>
          <v-col cols="12" md="6" class="d-flex align-center order-2 order-md-1"><div class="livingImg2"></div></v-col>
          <v-col cols="12" md="6" class="d-flex align-center order-1 order-md-2"><p v-html="$t('livingRoom.distinguishText')"></p></v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="d-flex justify-sm-start justify-center"><h4 v-html="$t('livingRoom.chic')"></h4></v-col>
          <v-col cols="12" md="6" class="d-flex align-center"><p v-html="$t('livingRoom.chicText')"></p></v-col>
          <v-col cols="12" md="6" class="d-flex align-center"><div class="livingImg3"></div></v-col>
        </v-row>
      </section>

    </div>
  </template>
  
  <style scoped>
.textSpacing {margin: 15px 0px;}
.textSpacing2 {margin: 30px 0px;}
.mSection {margin: 15px 0px 45px 0px;}
.bgBlack {
        background-color: rgba(0,0,0,0.9); padding:25px 0px 60px 0px;
    }
.welcomeTitle {position:absolute; font-size: 5vw !important;width:100%; text-align: center; bottom:18%; opacity: 0.6;}

/*#region Kitchen*/
.kitchenImg1 {
  width:100%; height:460px;
  background-image: url("../../public/Resources/images/img1_kitchen.png");
  background-position: center;
  background-repeat: no-repeat; 
  background-size: cover;
}
.kitchenImg2 {
  width:100%; height:460px;
  background-image: url("../../public/Resources/images/img2_kitchen.png");
  background-position: center;
  background-repeat: no-repeat; 
  background-size: cover;
}
.kitchenImg3 {
  width:100%; height:460px;
  background-image: url("../../public/Resources/images/img3_kitchen.png");
  background-position: center;
  background-repeat: no-repeat; 
  background-size: cover;
}
/*#endregion*/ 

/*#region Bedroom*/
  .bgBedroom {background-color: rgba(0,0,0,0.8);}
  .bedTitle { 
    position: absolute; width:100%;
    font-size: 105px; text-align: center;
    bottom:40%; opacity: 0.7;
  }
  .bedImg {width:462px; height: 358,88px;}
/*#endregion*/ 

/*#region LivingRoom*/
.livingImg1 {
  width:100%; height:460px;
  background-image: url("../../public/Resources/images/imgloung1.jpg");
  background-position: center;
  background-repeat: no-repeat; 
  background-size: cover;
}
.livingImg2 {
  width:100%; height:460px;
  background-image: url("../../public/Resources/images/imgloung2.jpg");
  background-position: center;
  background-repeat: no-repeat; 
  background-size: cover;
}
.livingImg3 {
  width:100%; height:460px;
  background-image: url("../../public/Resources/images/imgloung3.jpg");
  background-position: center;
  background-repeat: no-repeat; 
  background-size: cover;
}

/*#endregion*/ 

    @media handheld, only screen and (max-width: 450px) {}
    @media handheld, only screen and (max-width: 600px) {
    }
    @media handheld, only screen and (max-width: 650px) {
      .bedTitle { 
        font-size: 65px !important; 
        bottom:20% !important; 
  }
      .bedImg {width:230px !important; height: 175px !important;}
    }
    @media handheld, only screen and (max-width: 960px) {
      .welcomeTitle {font-size: 7vw !important; bottom:40%; opacity: 0.8;}
      .kitchenImg1 {
        height:340px;
        background-size: cover;
      }
      .kitchenImg2 {
        height:340px;
        background-size: cover;
      }
      .kitchenImg3 {
        height:340px;
        background-size: cover;
      }
      .bedTitle { 
        font-size: 85px; 
        bottom:25%; 
  }
      .bedImg {width:299px; height: 227,5px;}
    }
    @media handheld, only screen and (max-width: 1030px) {
    }
    @media handheld, only screen and (max-width: 1279px) {

    }
    @media handheld, only screen and (max-width: 1378px) {
    }

</style>

  <script>
  import pageTranslations from '../../src/assets/i18N/concept.json'
  
  export default {
    name: 'Concept',
    data: () => ({

    }),

    mounted() {
        this.$i18n.mergeLocaleMessage('fr', pageTranslations.fr);
        this.$i18n.mergeLocaleMessage('en', pageTranslations.en);

    },
    methods:{
    },
    components: {
    }
  }
  </script>
<template>
    <div>
        <h3 class="f-primary" v-html="$t('shipping.title')"></h3>
        <h4 class="mb-6 oswald2" v-html="$t('shipping.slogan')"></h4>
        <div v-if="ready">
            <v-row class="ma-0" style="margin: 0px;">
              <v-col>
                <v-row class="grid-pad">
                  <v-col>
                    <v-row>
                        <v-col cols="12"><h2 class="gridTitleStyle" v-html="$t('shipping.delivered')"></h2></v-col>    
                    </v-row>
                    <div v-if="current.shippingAddress">
                        <v-row>
                          <v-col class="txt-r">
                            <div >
                              <v-btn @click="setAddress('Pickup'); " :class="{'v-secondary': current.shippingAddress.typeId == 3}" class="btnShipping"><span class="btnShippingText" v-html="$t('deliveryBtn.pickUp')"></span></v-btn>
                              <v-btn @click="setAddress('Invoice')" :class="{'v-secondary': current.shippingAddress.typeId == 1}" class="btnShipping"><span class="btnShippingText" v-html="$t('deliveryBtn.sameAdress')"></span></v-btn>
                              <v-btn @click="setAddress('Shipping')" :class="{'v-secondary': current.shippingAddress.typeId == 2}" class="btnShipping"><span class="btnShippingText" v-html="$t('deliveryBtn.otherAdress')"></span></v-btn>
                              <v-select class="mt-8" v-if="current.shippingAddress.typeId == 3" v-model="homeAddress" :items="['Drummondville']" required />
                              <h3 v-if="current.shippingAddress.typeId == 3" v-html="$t('shipping.warningPickup')"></h3>
                              <h3 v-else class="txt-r" v-html="$t('shipping.warningShipping')"></h3>
                            </div>
                          </v-col>
                        </v-row>
                        <div>
                          <v-row :class="{'gray': !(current.shippingAddress.typeId == 2)}">
                            <v-col class="mt-5" cols="12"><h3 :class="$filters.validations(v$.current.shippingAddress.street)" v-html="$t('shipping.street')"></h3></v-col>
                            <v-col cols="12" v-if="v$.current.shippingAddress.street.$invalid"><p class="errorText" v-html="$t('shipping.nameError')"></p></v-col>
                            <v-col cols="12"><v-text-field @change="changeAddress()" v-model="v$.current.shippingAddress.street.$model" :readonly="!(current.shippingAddress.typeId == 2)"/></v-col>
                          </v-row>
                          <v-row :class="{'gray': !(current.shippingAddress.typeId == 2)}">
                            <v-col cols="12"><h3 :class="$filters.validations(v$.current.shippingAddress.city.name)" v-html="$t('shipping.city')"></h3></v-col>
                            <v-col cols="12" v-if="v$.current.shippingAddress.city.name.$invalid"><p class="errorText" v-html="$t('shipping.cityWarning')"></p></v-col>
                            <v-col cols="12"><v-text-field @change="changeAddress()" v-model="v$.current.shippingAddress.city.name.$model"  :readonly="!(current.shippingAddress.typeId == 2)" /></v-col>
                          </v-row>
                          <v-row :class="{'gray': !(current.shippingAddress.typeId == 2)}">
                            <v-col cols="12"><h3 :class="$filters.validations(v$.current.shippingAddress.zipCode)"  v-html="$t('shipping.zipCode')"></h3></v-col>
                            <v-col cols="12" v-if="v$.current.shippingAddress.zipCode.$invalid"><p class="errorText" v-html="$t('shipping.zipCodeWarning')"></p></v-col>
                            <v-col cols="12"><v-text-field @change="changeAddress()"  v-model="v$.current.shippingAddress.zipCode.$model" :readonly="!(current.shippingAddress.typeId == 2)" /></v-col>
                          </v-row>
                          <v-row class="">
                            <v-col cols="12"><h3 v-html="$t('shipping.details')"></h3></v-col>
                            <v-col cols="12"><v-textarea v-model="current.comment"  clearable required /></v-col>
                          </v-row>
                        </div>

                    </div>

                  </v-col>
                </v-row>
              </v-col>
            </v-row>
        </div>

    </div>
</template>

<style scoped>
  h3 {font-size: 24px;}
  h4 {font-size: 18px;}
  p {font-size: 14px; }
  .v-col{margin: 0px; padding: 0px;}
  .btnShipping {padding: 4px !important; margin: 4px !important;}
  .btnShippingText {color: rgb(var(--primary));}
  @media handheld, only screen and (max-width: 400px) {}
  @media handheld, only screen and (max-width: 600px) {}
  @media handheld, only screen and (max-width: 754px) {
    h2 {font-size: 18px;}
    h3 {font-size: 24px;}
    h4 {font-size: 18px;}
    p {font-size: 14px; }
  }
  @media handheld, only screen and (max-width: 960px) {
    .btnShipping {width: 100%;}
  }
  @media handheld, only screen and (max-width: 1279px) {}
</style>

<script>
import { apiECommerceExtension } from '@/mixins/apiECommerceExtension.js';
import pageTranslations from '../../../../src/assets/i18N/eCommerce/order.json';
import { required, email, minLength } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core';

export default {
    name: 'OrderShipping', 
    mixins: [apiECommerceExtension],
    props: { 
      item: Object, 
      dirty: Boolean,
    },
    setup () {  return { v$: useVuelidate() } },
    data: () => ({
      current:null,
      homeAddress: 'Drummondville',
      otherShippingAdresse: false,
      livraison: 'Invoice', //Invoice, Shipping, Pickup
      DefaultAddress: {},
      isDirty: false,
    }),
    validations: {
      current: {
        shippingAddress: {
          street: { required },
          city: {
            name: { required }
          },
          zipCode: { required,  min: minLength(6) }
        }
      }
      
    },
    computed:{
        ready: async function () {
            let show = false;
            if(this.item) {
                this.current = this.item;
                show = true;
            }
            if(this.dirty){this.isDirty = this.dirty}
            return show;
        },

    },
    watch: {
      homeAddress: function(){
        if(this.current.shippingAddress.typeId = 3) {
            if(this.homeAddress == 'Drummondville'){
              this.current.shippingAddress.street = '656 Bd Lemire';
              this.current.shippingAddress.city.name = 'Drummondville';
              this.current.shippingAddress.city.state.name = 'Québec';
              this.current.shippingAddress.city.state.country.name = 'Canada';
              this.current.shippingAddress.zipCode = 'J2C 7W9';
              this.current.shippingAddress.typeId = 3;
              this.livraison == 'Pickup';
            }
          }
      },

    },
    mounted: async function() {
        this.$i18n.mergeLocaleMessage('fr', pageTranslations.fr);
        this.$i18n.mergeLocaleMessage('en', pageTranslations.en);

        let DA = await this.GetDefaultAddress();
        this.DefaultAddress = JSON.parse(JSON.stringify(DA));

        if(this.current.shippingAddress != null){
          if(this.current.shippingAddress.typeId == 2) { this.setAddress('Shipping');} 
          else if(this.current.shippingAddress.typeId == 3){this.setAddress('Pickup');}
          else{this.setAddress('Invoice');}
        } else { this.setAddress('Pickup'); }

    },
  
    methods: {
        setAddress: function(s){
            if(!this.current.shippingAddress){this.current.shippingAddress = JSON.parse(JSON.stringify(this.DefaultAddress));}
            if(s == 'Invoice'){
              if(this.current.shippingAddress.typeId != 1){this.isDirty = true; this.$emit("update:dirty", this.isDirty);}
              this.current.shippingAddress = JSON.parse(JSON.stringify(this.current.account.address));
              this.livraison == 'Invoice'
            }
            else if(s == 'Pickup'){
              if(this.current.shippingAddress.typeId != 3){this.isDirty = true; this.$emit("update:dirty", this.isDirty);}
              this.current.shippingAddress = JSON.parse(JSON.stringify(this.DefaultAddress));
              this.current.shippingAddress.street = '656 Bd Lemire';
              this.current.shippingAddress.city.name = 'Drummondville';
              this.current.shippingAddress.city.state.name = 'Québec';
              this.current.shippingAddress.city.state.country.name = 'Canada';
              this.current.shippingAddress.zipCode = 'J2C 7W9';
              this.current.shippingAddress.typeId = 3;
              this.livraison == 'Pickup'
            }
            else {
                if(this.current.shippingAddress){
                
                  if(this.current.shippingAddress.typeId != 2){
                    this.isDirty = true; 
                    this.$emit("update:dirty", this.isDirty);
                    this.current.shippingAddress = JSON.parse(JSON.stringify(this.DefaultAddress));
                    this.current.shippingAddress.street = '';
                    this.current.shippingAddress.city.name = '';
                    this.current.shippingAddress.city.state.name = 'Québec';
                    this.current.shippingAddress.city.state.country.name = 'Canada';
                    this.current.shippingAddress.zipCode = '';
                  }
              
                } else {
                this.isDirty = true;
                this.$emit("update:dirty", this.isDirty);
                this.current.shippingAddress = JSON.parse(JSON.stringify(this.DefaultAddress));
                this.current.shippingAddress.street = '';
                this.current.shippingAddress.city.name = '';
                this.current.shippingAddress.city.state.name = 'Québec';
                this.current.shippingAddress.city.state.country.name = 'Canada';
                this.current.shippingAddress.zipCode = '';
                
                }
                this.current.shippingAddress.typeId = 2;
                this.livraison == 'Shipping'
            }
        },
        changeAddress: function(){
          this.isDirty = true;
          this.$emit("update:dirty", this.isDirty);
        }
    },

    components: {},
}
</script>
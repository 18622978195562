<template>
    <div class="grid-pad minHeight BGImg">
        <h1 class="h1Height oswald" v-html="$t('paid.title')"></h1>
        <!--Information selon Status-->
        <div v-if="status == 'Pending'" class="grid grid-pad mt-10"> 
            <h2 class="soustitre" v-html="$t('paid.treatment')"></h2>
            <v-row>   
                <v-col>
                    <v-progress-linear class="" color="rgb(var(--primary))" indeterminate striped height="8"></v-progress-linear>
                </v-col>
            </v-row>
        </div>
        <div v-if="status == 'Succeed'" class="grid grid-pad mt-10"> 
            <v-row>
                <h2 class="soustitre" v-html="$t('paid.finalized')"></h2>
            </v-row>
            
        </div>
        <div v-if="status == 'Failed'" class="grid grid-pad mt-10"> 
            <v-row>
                <h2 class="soustitre" v-html="$t('paid.problem')"></h2>
            </v-row>
            <v-row>
                <div class="">

                    <v-btn class="mt-5 orderBtnStyle1" style="">
                        <div >   
                            <h2 @click="navigateToInvoice()"  class="" v-html="$t('paid.tryAgain')"></h2>
                        </div>
                    </v-btn>
                </div>

            </v-row>
        </div>
    </div>
</template>

<style scoped>
    .minHeight { min-height: 500px;}
    .BGImg {background-image: url('../../../../public/Resources/images/nos-avantages.jpg'); background-position: bottom right;}
    .orderBtnStyle1{display: block; height:50px !important; background-color: rgb(var(--primary)); border: 1px solid gray; border-radius: 10px; width:240px;}
    .orderBtnStyle1:hover {background-color: rgb(var(--secondary)); color: white;}
    @media handheld, only screen and (max-width: 960px) {
      h2{font-size: 18px;}
    }

</style>
    
<script>
import { apiECommerceExtension } from '@/mixins/apiECommerceExtension.js'
import pageTranslations from '../../../../src/assets/i18N/eCommerce/invoice.json';

export default {
    name: 'Paid', 
    mixins: [apiECommerceExtension],
    data: () => ({
        status:'Pending', // Succeed, Pending, Failed
        tried: 0,
        confirmed: false,
        param: null,
        exec: {paymentId: '', payerId: ''},
    }),
    mounted() {
        this.$i18n.mergeLocaleMessage('fr', pageTranslations.fr);
        this.$i18n.mergeLocaleMessage('en', pageTranslations.en);
        this.param = this.$route.params.sid;
        const urlParams = new URLSearchParams(window.location.search);
        this.exec.paymentId = urlParams.get('paymentId');
        this.exec.payerId = urlParams.get('PayerID');
        if(this.exec.paymentId && this.exec.payerId){this.transactionCompleted();} else{ this.status = 'Failed'; }
    },
    methods: {
        navigateToInvoice: async function () { await this.ClearInvoice(); this.$router.push('/invoices/' + this.param); },
        transactionCompleted: async function() {
            
            if(this.exec.paymentId && this.exec.payerId){
                this.tried++;
                let confirmed = await this.PaypalPaided(this.param,this.exec);
                if(confirmed){this.status = 'Succeed'; this.navigateToInvoice();}
                else{
                    if(this.tried < 2){this.transactionCompleted()} else {this.status = 'Failed';}
                }
            }else { this.status = 'Failed';}
        }
    },
    
    components: {
    },
}
</script>
import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/home.vue'
import Concept from '../views/concept.vue'
import garageDoor from '../views/garageDoor.vue'
import product from '../views/product.vue'
import onlineStore from '../views/onlineStore.vue'
import Policy from '../views/Policy.vue'
import order from '../views/eCommerce/Order/order.vue'
import invoice from '../views/eCommerce/invoice.vue'
import paypal_paid from '../views/eCommerce/Paypal/paid.vue'
import paypal_canceled from '../views/eCommerce/Paypal/canceled.vue'

const routes = [
  {
    path: '/',
    name: 'garageDoor',
    component: garageDoor
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: "/",
  },
  {
    path: '/home',
    name: 'home',
    component: Home
  },
  {
    path: '/concept',
    name: 'concept',
    component: Concept
  },
  {
    path: '/PolitiqueDeConfidentialiter',
    name: 'Policy',
    component: Policy
  },
  {
    path: '/product/:id',
    name: 'Product',
    component: product
  },
  {
    path: '/onlineStore/:id',
    name: 'onlineStore_ID',
    component: onlineStore
  },
  {
    path: '/onlineStore',
    name: 'onlineStore',
    component: onlineStore
  },
  {
    path: '/boutique/:id',
    name: 'onlineStore_ID',
    component: onlineStore
  },
  {
    path: '/boutique',
    name: 'onlineStore',
    component: onlineStore
  },
  {
    path: '/order',
    name: 'order',
    component: order
  },
  {
    path: '/invoice',
    name: 'invoice',
    component: invoice
  },
  {
    path: '/invoices/:id',
    name: 'invoice_ID',
    component: invoice
  },
  {
    path: '/invoices/:sid/paypal/paid',
    name: 'paypal_paid',
    component: paypal_paid
  },
  {
    path: '/invoices/:sid/paypal/canceled',
    name: 'paypal_canceled',
    component: paypal_canceled
  }
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, SavedPosition) {
    if (to.hash) {
      const el = window.location.href.split("#")[1];
      if (el.length) {
        document.getElementById(el).scrollIntoView({ behavior: "smooth" });
      }
    } else if (SavedPosition) {
      return SavedPosition;
    } else {
      document.getElementById("app").scrollIntoView({ behavior: "smooth" });
    }
  },
})

export default router

<template>
    <div class="">
        <v-row class="ma-0 orderWidth">
            <v-col class=" pa-0">
                <div class="gridpadOrder">
                    <v-row>
                        <v-col class="d-flex justify-center justify-sm-start py-0">
                          <h2 class="titleOrder cursor">
                            <span class='uppercase light'>{{$t('general.items')}}</span>
                          </h2>
                        </v-col>
                        <v-col cols="12" class="d-flex justify-center justify-sm-start py-0"><div style="width: calc(29vw - 75px); margin: -10px 0px 10px 0px; border-bottom: solid 2px black;"></div></v-col>
                    </v-row>

                    <div v-if="this.order">
                        <!--Items header-->
                        <div class="showHeader">
                            <v-row class="ma-0 mt-10 d-flex ">
                                <v-col style="width: 100%; min-width: 225px; max-width: 225px;"  class="py-0"></v-col>
                                <v-col style="width: 100%; min-width: 550px;"  class="d-flex py-0 d-flex">
                                  <p class="uppercase">{{$t('general.name')}}</p>
                                </v-col>
                                <v-col style="width: 100%; min-width: 80px; max-width: 100px;"  class="d-flex justify-start py-0"><p v-html="$t('general.total')"></p></v-col>
                                <v-col style="width: 100%; min-width: 80px; max-width: 100px;" class="d-flex justify-end py-0"><p v-html="$t('general.undo')"></p></v-col>
                            </v-row>
                        </div>


                        <!--Items-->
                        <div v-if="this.order.items && this.order.items.length > 0" >
                            <v-row  v-for="(item, i) in this.order.items" :key="i" class="ItemsBox">
                                <v-col class="d-flex justify-center ItemimageGrid" style="width: 100%; min-width: 225px; max-width: 225px; margin-left: auto; margin-right: auto;" > <div  class="ItemsImgBox"><img v-if="item.url"  class="ItemsImg" :src="item.url" :truesrc="item.url"/> <img v-else class="ItemsImg" :src="this.image" :truesrc="this.image"/></div></v-col>
                                <v-col class="itemInfo">
                                    <v-row>
                                        <v-col class="itemName" style="" >
                                          <v-row >
                                            <v-col cols="12"><h2 class="" v-html="item.description"></h2></v-col>
                                            <v-col cols="12"><p style="color: black;" v-html="$filters.textWithBR(item.options) "></p></v-col>
                                          </v-row>
                                          <v-row style="width: 100%; min-width: 100%;"></v-row>
                                        </v-col>
                                        <v-col class="d-flex justify-end itemPrice" style="">
                                            <v-row class="">
                                                <v-col class="d-flex justify-start"><h2 class="">{{item.price}}$</h2></v-col>
                                                <v-col class="d-flex justify-center"><h2 class="txt-c hoverItem" @click="removeItem(i);">X</h2></v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                
                            </v-row>
                        </div>

                        <div v-else>
                            <h2 class="mt-10" v-html="$t('general.noItems')"></h2>
                        </div>
                    </div>

                    <!--Information-->
                    <v-row>
                        <v-col class="d-flex justify-center justify-sm-start py-0">
                          <h2 class="titleOrder uppercase cursor">
                            <span class='uppercase light'>{{$t('general.info')}}</span>
                          </h2>
                        </v-col>
                        <v-col cols="12" class="d-flex justify-center justify-sm-start py-0"><div style="width: calc(21vw + 67px); margin: -10px 0px 10px 0px; border-bottom: solid 2px black;"></div></v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <!--Facturation-->
                            <v-expansion-panels v-model="panels" multiple >
                                <v-expansion-panel class="mb-4">
                                  <v-expansion-panel-title class="pa-0 v-secondary-light-9">
                                    <v-row class="ma-0">
                                      <v-col cols="9" class="d-flex align-center justify-start py-0"><h2 class="panelTitle bold oswald pa-0 ma-0" v-html="$t('general.invoice')"></h2></v-col>
                                      <v-col cols="3" class="d-flex align-center justify-end py-0"><div style="height:35px;"><img class="" style="width:100% ; height:100%;" src="../../../../public/Resources/images/icon/expand_more_white_24dp.svg"/></div></v-col>
                                    </v-row>
                                  </v-expansion-panel-title>
                                  <v-expansion-panel-text class="v-secondary-light-2">
                                    <OrderBilling v-model:item="order" v-model:dirty="isDirty"/>
                                  </v-expansion-panel-text>
                                </v-expansion-panel>

                                <!--Shipping-->
                                <v-expansion-panel class="mb-4">
                                  <v-expansion-panel-title class="pa-0 v-secondary-light-9">
                                    <v-row class="ma-0">
                                      <v-col cols="9" class="d-flex align-center justify-start py-0"><h2 class="panelTitle bold oswald pa-0 ma-0" v-html="$t('general.delivery')"></h2></v-col>
                                      <v-col cols="3" class="d-flex align-center justify-end py-0"><div style="height:35px;"><img class="" style="width:100% ; height:100%;" src="../../../../public/Resources/images/icon/expand_more_white_24dp.svg"/></div></v-col>
                                    </v-row>
                                  </v-expansion-panel-title>
                                  <v-expansion-panel-text class="v-secondary-light-2">
                                    <OrderShipping v-model:item="order" v-model:dirty="isDirty"/>
                                  </v-expansion-panel-text>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-col>
                    </v-row>

                     <!--Legal-->
                     <v-row>
                        <v-col class="d-flex justify-center justify-sm-start py-0">
                          <h2 class="titleOrder uppercase cursor">
                            <span class='uppercase light'>{{$t('general.legal')}}</span>
                          </h2>
                        </v-col>
                        <v-col cols="12" class="d-flex justify-center justify-sm-start py-0"><div style="width: calc(18vw + 125px); margin: -10px 0px 10px 0px; border-bottom: solid 2px black;"></div></v-col>
                        <v-col cols="12">
                            <p class="txt-j" v-html="$t('general.legalText')"></p>
                        </v-col>
                    </v-row>

                </div>
                
            </v-col>

            <!--Summary-->
            <v-col class="sumWidth pa-0">
                <Summary step="order" v-model:item="order" v-model:dirty="isDirty" v-model:loading="isLoading" @eCommerceCompleted="completeOrder" @eCommerceNext="refresh"  @eCommerceBack="back" />
            </v-col>
        </v-row>
        
    </div>
</template>

<style scoped>
.showHeader{display: block;}
.gridpadOrder {padding:30px 7%;}
.cursor {cursor: pointer;}
p {font-size: 18px}
.titleOrder {color: rgb(var(--primary)); text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.50); font-size:48px; font-weight: 200; }
.ItemsBox {position:relative; color: rgba(var(--primary));  height: 100%; background-color: #FFF; box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25); margin: 30px 0px 30px 0px;}
.ItemsImgBox {height: 162px; width:180px; display: inline-block;}
.ItemsImg {width:180px; height:100%; border-radius: 5%;}
.hoverItem:hover {color: rgb(var(--secondary)); cursor: pointer;}
.panelTitle {color: rgb(var(--primary)); text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.25);}
.orderWidth {min-width:605px;; max-width: 1912px;}
.sumWidth {min-width:340px; max-width: 400px;}
.itemName {width: 100%; min-width: 500px; max-width: 900px; margin-right: auto;}
.itemH2 p { color: black !important; }
.itemPrice { width: 100%; min-width: 150px; max-width: 200px; margin-left: auto; margin-right: auto; }
.itemInfo{min-width: 500px;}
@media handheld, only screen and (max-width: 600px) {
    .titleOrder { font-size:28px !important; }
    .orderWidth {min-width:100%;}
}
@media handheld, only screen and (max-width: 754px) {
    .gridpadOrder {padding:0px 15px;}
}
@media handheld, only screen and (max-width: 960px) {
    .titleOrder {font-size:36px;}
    p {font-size: 16px}
    .gridpadOrder {padding:30px 15px;}
    .sumWidth {min-width:100%; max-width: 100%;}
    .ItemsBox {width: 100%;}
    .itemName { min-width: 100%; max-width: 100%; margin-left: auto; }
    .itemPrice {  min-width: 100%; max-width: 100%;  }
    .itemName h2 {  text-align: center;  }
    .itemInfo{min-width: 100%; max-width: 100%;} 
  
}

@media handheld, only screen and (max-width: 1279px) {
}
@media handheld, only screen and (max-width: 1378px) {}
@media handheld, only screen and (max-width: 1502px) {
    .showHeader{display: none;}
}
</style>

<script>
import Summary from '@/components/infoPayment.vue';
import Header from '@/components/header.vue';
import phone from '@/components/commons/Phone.vue';
import OrderShipping from '@/views/eCommerce/Order/order_shipping.vue'
import OrderBilling from '@/views/eCommerce/Order/order_billing.vue'
import pageTranslations from '../../../assets/i18N/eCommerce/order.json';
import { apiECommerceExtension } from '@/mixins/apiECommerceExtension.js';
import { DatetimeFormat } from 'vue-i18n';

export default {
  name: 'Order',
  mixins: [apiECommerceExtension],
  props: {
      summary: Object, // pour info paiement
  },
  data: () => ({
    order:null,
    panels: [],
    validationObject:[],
    validationFinal:{
      valid: true,
      error: [],
    },
    image: 'https://abstracxionstorage.blob.core.windows.net/habitat3d/products/Default.jpg',
    isDirty: false,
    isLoading: false,
    //Billing
    homeAddress: 'Drummondville',
    otherShippingAdresse: false,
    livraison: true,
    DefaultAddress: {},
    ShippingDate:{min:""},
  }),
  watch: {
    livraison: function() {
      // Fonction appelée lorsqu'une modification est détectée
      
      if(this.livraison == false) {
        this.otherShippingAdresse = false;
      }else {
        this.otherShippingAdresse = true;
      }
    },

  },
  computed: {},
    beforeCreate: function () {
      this.$i18n.mergeLocaleMessage('fr', pageTranslations.fr);
      this.$i18n.mergeLocaleMessage('en', pageTranslations.en);
    },
    mounted: async function () {
      this.order = await this.GetOrder();

      //this.validation();
      $App.event.$on("ecommerce.order.Invalid", this.onInvalid);
    },
    methods: {
      removeItem: async function (ItemIndex) { this.order = await this.DeleteItem(ItemIndex); },
      validation: async function(){
        let valid = false;
        let validation = this.order.validations;
        this.validationFinal = { valid: true, error: [] } 
        let errorFlag = { name: false, phone: false, email: false, street: false, city: false, zipCode: false };
        if(validation){
            valid = true;
            validation.forEach(e => {
              if(e.severity == 3) {                
                if(e.field == "Account.Name") { valid = false; errorFlag.name = true; }
                else if(e.field == "Account.Phone.Number") { valid = false; errorFlag.phone = true; }
                else if(e.field == "Account.Email.Address") { valid = false; errorFlag.email = true; }
                else if(e.field == "Recipient.Name") { valid = false; errorFlag.name = true; }
                else if(e.field == "Recipient.Phone.Number") { valid = false; errorFlag.phone = true; }
                else if(e.field == "Address.Street") { valid = false; errorFlag.street = true; }
                else if(e.field == "Address.ZipCode") { valid = false; errorFlag.zipCode = true; }
                else if(e.field == "Address.City.Name") { valid = false; errorFlag.city = true; }
                else{
                  if(this.validationFinal.valid == true){this.validationFinal.valid = false;}
                  this.validationFinal.error.push('field: ' + e.field + '  message ' + e.message);
                }
              }
            });
            if(valid == false){this.error = errorFlag;}
        }
        $App.event.$emit("ecommerce.order.Invalid");
        return valid;
      },
      completeOrder: async function() {
        this.isLoading = true;
        this.order.deliveryOn = new Date();
        this.order.recipient.name = this.order.account.name;
        this.order.recipient.phone = this.order.account.phone;

        let orderUpdated = await this.UpdateOrder(this.order);
        if(orderUpdated){ 
          
          this.order = orderUpdated;
          let valid = await this.validation();
          let InvoiceRef = null;

          if(valid){
            if(this.validationFinal.valid == true){ InvoiceRef = await this.CompleteOrder(this.order); }
            else{  $App.event.$emit("app_error", this.validationFinal.error); }
            

          } else { if(this.validationFinal.valid == false){ $App.event.$emit("app_error", this.validationFinal.error); } }
          if(InvoiceRef != null){ this.isLoading = false; this.navigateTo(InvoiceRef); }else{this.isLoading = false;} 
        }else {$App.event.$emit("ecommerce.order.Invalid");}
  
      },
      refresh: async function(){
        console.log('refresh');
        this.isLoading = true;
        this.order.deliveryOn = new Date();
        this.order.recipient.name = this.order.account.name;
        this.order.recipient.phone = this.order.account.phone;

        let orderUpdated = await this.UpdateOrder(this.order);
        let valid = await this.validation();
        if(this.validationFinal.valid == false){ $App.event.$emit("app_error", this.validationFinal.error); }
        this.isDirty = false;
        this.isLoading = false;
        window.location.reload(); 
      },
      navigateTo: function (InvoiceRef) {
          let cleared = this.ClearOrder();
          $App.event.$emit("app_ecommerceOrderChanged");
          if(cleared == true){ this.$router.push('/invoices/' + InvoiceRef);}        
      },
      back: function(){ this.$router.push('/Boutique'); },
      onInvalid: function() {
        //console.log('invalid...');
        this.panels = [0,1];

      }
    },
  
  components: {
    Summary,
    phone,
    Header,
    OrderBilling,
    OrderShipping
  },
}
</script>
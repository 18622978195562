
<template>
    <div>
        <div class="bgFooterBox">
            <div class="d-flex flex-column align-center bgBlack ">
                <v-row class="ma-0">
                    <v-col cols="12" class="d-flex align-center justify-center"><div class="logo"></div></v-col>
                </v-row>
                <v-row class="ma-0 d-flex justify-center widthBox">
                    <v-col cols="12" md="4" class="order-1">
                        <v-row><v-col cols="12" class="d-flex justify-center"><div><h2 class="divPad colTitle oswald2" v-html="$t('footer.division')"></h2></div></v-col></v-row>
                        <v-row>
                            <v-col class="d-flex justify-center">
                                <div class="d-flex flex-column align-center align-md-start" style="position:relative;">
                                    <router-link to="/" v-html="$t('footer.divisionSubtitle')" class="divisionSubtitle bold oswald2 f-primary-light-9"></router-link>
                                    <p v-html="$t('footer.divisionSubtitle2')" class="divisionSubtitle bold oswald2 f-primary-light-9"></p>
                                    <p v-html="$t('footer.divisionSubtitle3')" class="divisionSubtitle bold oswald2 f-primary-light-9"></p>
                                    <p v-html="$t('footer.divisionSubtitle4')" class="divisionSubtitle bold oswald2 f-primary-light-9"></p>
                                    <img src="../../public/Resources/images/icon/logobanner.png" class="icon3D"/>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="4" class=" order-3 order-md-2">
                        <v-row><v-col><div><h2 class="txt-c colTitle oswald2" v-html="$t('footer.join')"></h2></div></v-col></v-row>
                        <v-row>
                            <v-col>
                                <div class="txt-c">
                                    <p class="colTxt">819-473-3114</p>
                                    <a href="mailto:ventes@habitat3d.com"><p class="colTxt mt-2 mb-3" style="text-decoration: underline;">ventes@habitat3d.com</p></a>

                                    <v-col class="d-flex justify-center align-center py-0">
                                        <a href="https://www.youtube.com/channel/UCI80pvPRU8o-KxW8OLzmYIg" target="_blank">
                                            <img class="icon" src="../../public/Resources/images/icon/youtubelogo.png" />
                                        </a>
                                        <a href="" target="_blank">
                                            <img class="icon" src="../../public/Resources/images/icon/facebooklogo.png" />
                                        </a>
                                        <a href="" target="_blank">
                                            <img class="icon" src="../../public/Resources/images/icon/marketinglogo.png" />
                                        </a>
                                    </v-col>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="4" class="order-2 order-md-3 d-flex flex-column align-center" >
                        <v-row><v-col><h2 class=" txt-c colTitle oswald2" v-html="$t('footer.openHour')"></h2></v-col></v-row>
                        <v-row class="d-flex justify-center" style="height: 80%;">
                            <v-col class=" d-flex flex-column align-center justify-center" >
                                <p class="colTxt" v-html="$t('footer.openHourTxt')"></p>
                                <p class="colTxt" v-html="$t('footer.openHourTxt2')"></p>
                                <p class="colTxt uppercase mt-5" v-html="$t('footer.joinTxt')"></p>
                                <p class="colTxt">{{ $t('footer.copyWrite') }} &copy;2024.04.25</p>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col >
                        <div class="cookieLink "><p @click="openCookie()" class="txt-r" v-html="$t('footer.CookieLink')"></p></div>
                        <div class="cookieLink pr-5"><p @click="navigateToPolicy()" class="txt-r" v-html="$t('footer.policyLink')"></p></div>
                    </v-col>
                </v-row>
            </div>

        </div>
    </div>
    
</template>
<style scoped>
    .v-row{width: 100%;}
    .txt-r {float: right;}
    .cookieLink { color: rgb(var(--tierce)) ; text-decoration: none ; cursor: pointer; display: inline-block; font-family: Oswald; float: right;}
    .cookieLink:hover p { text-decoration: underline !important; color: rgb(var(--primary)); }
    .icon { display:block; height: 35px; width:35px; margin: 20px 15px 0px 15px;}
    .icon3D { position:absolute; display:block;  height: 39px; width:50px; top: 0px; right:-70px;}
    .logo { width: 225px; height:45px; background-image: url('../../public/Resources/images/logo/Habitat3D.png'); background-position:center; background-repeat: no-repeat; background-size: cover;}
    .colTitle {font-size: 24px; text-transform: uppercase; color:white }
    .colTxt {font-size: 14px; color:white; }
    .divisionSubtitle {font-size: 24px; text-transform: uppercase; }
    .bgFooterBox { width: 100%; height: 100%; background-image: url('../../public/Resources/images/footer/fondmetal.png'); background-position: center; background-repeat: no-repeat; background-size: cover; }
    .bgBlack { background-color: rgba(0,0,0,0.9); padding:50px 0px; }
    .widthBox { width: 95%;}
    .divPad {padding:0px 0px 0px 45px;}

    @media handheld, only screen and (max-width: 450px) {}
    @media handheld, only screen and (max-width: 600px) {}
    @media handheld, only screen and (max-width: 960px) {
        .divPad {padding:0px 0px 0px 0px;}
        .divisionSubtitle {font-size: 22px !important; text-transform: uppercase; }
    }
    @media handheld, only screen and (max-width: 1030px) {
        .widthBox { width: 80%;}
    }
    @media handheld, only screen and (max-width: 1279px) {
        .colTxt {font-size: 12px; color:white; }
        .colTitle {text-transform: uppercase; color:white }

    }
    @media handheld, only screen and (max-width: 1378px) {}
</style>
<script>
import translations from '../assets/i18N/global.json';
export default {
    name: 'Footer',
    data: () => ({}),
    mounted() {
        this.$i18n.mergeLocaleMessage('fr', translations.fr);
        this.$i18n.mergeLocaleMessage('en', translations.en);
    },
    methods: {
        switchLanguage: function () {
         this.$i18n.locale = this.currentLang() === 'en' ? 'fr' : 'en';     
        },
        currentLang() {
            return this.$i18n.locale;
        },
        navigateToPolicy: function(){
            this.$router.push('/PolitiqueDeConfidentialiter');
        },
        openCookie: function(){
            $App.event.$emit('openCookie');
        }
    },
    component: {}
}
</script>
<template>
    <div > <!--class="md-content"-->
        <!-- RL not remove next div with v-if (use for binding)... -->
        <div v-if="ready">
            <div v-if="current" class="">
                <v-row class="" >
                    <v-col cols="12"><h5 :class="$filters.validations(v$.current.number)" v-html="$t('billing.tel')"></h5></v-col>
                    <v-col cols="12">
                        <v-text-field label="xxxxxxxxxx" single-line @input="formatPhoneNumber" v-model="v$.current.number.$model" @change="updated"  :readonly="isReadOnly" />
                    </v-col>
                    <v-col cols="12" v-if="v$.current.number.$invalid"><p class="errorText mt-n11" v-html="$t('billing.telError')"></p></v-col>
                </v-row>
            </div>
        </div>

    </div>
</template>
<style scoped>
  h2 {font-size: 24px;}
  h3 {font-size: 32px;}
  h4 {font-size: 24px;}
  p {font-size: 16px; }
</style>
<script>
    import pageTranslations from '../../assets/i18N/eCommerce/order.json';
    import { required, email, minLength } from '@vuelidate/validators'
    import { useVuelidate } from '@vuelidate/core';
    export default {
        name: 'phone',
        props: {
            item: Object,
            includeDescription: Boolean,
            readonly: Boolean,
        },
        setup () {  return { v$: useVuelidate() } },
        data: () => ({
            types: [],
            current: {},
        }),
        validations: {
            current:{
                number:{required, min: minLength(7) }
            }
        },
        computed: {
            ready: function () {
                let val = false;
                if (this.item) {
                    this.current = this.item;
                    val = true;
                }
                return val;
            },
            isReadOnly: function() {
                return this.readonly;
            },
        },
        mounted: async function () {
            this.$i18n.mergeLocaleMessage('fr', pageTranslations.fr);
            this.$i18n.mergeLocaleMessage('en', pageTranslations.en);    
        },
        methods: {
            updated: function(){this.$emit("update:item", this.current);},
            formatPhoneNumber() {
                    // Supprimer les tirets et autres caractères non numériques
                this.current.number = this.current.number.replace(/[^0-9]/g, '');
                let number  = 0; 
                let validNumber = true;

                try{number = Number(this.current.number); } catch { validNumber= false;}
                if(validNumber) { this.current.number = number; }
                
                    // Limiter la longueur du numéro à 9 chiffres
                if (this.current.number.length > 11) {            
                    this.current.number = this.current.number.substring(0, 11);
                } else if(this.current.number.length == 0) { this.current.number = 0; }
            },
        },
    }
</script>

<template>
    <div>
      <v-row class="ma-0">
        <v-col cols="12" lg="5" class="pa-0 d-flex align-center">
          <v-container id="image">
            <div v-if="this.image" class="whiteBG d-flex justify-center"><v-img class="productImg" :src="this.image"></v-img></div>                 
          </v-container>
        </v-col>
        <v-col cols="12" lg="7" style="position:relative">
          <div class="dm-none" style="position: absolute; height:200px; margin: 60px 0px 0px 0px ; border-left: solid 2px red;"></div>
          <div class="gridpadProduct">

            <!--Title-->
            <v-row>
              <v-col><h2 class="bold uppercase oswald2">{{Product.title}}</h2></v-col>
            </v-row>

            <!--Description-->
            <v-row>
              <v-col><p class="txt-j" style="min-height: 250px;">{{Product.description}}</p></v-col>
            </v-row>

            <!--panels-->
            <!--
            <v-row>
              <v-col cols="12">
                <v-expansion-panels>
                  <v-expansion-panel class="mb-4">
                    <v-expansion-panel-title class="pa-0 v-secondary-light-9">
                      <v-row class="ma-0">
                        <v-col class="d-flex align-center justify-start py-0"><h2 class="panelTitle bold oswald pa-0 ma-0">Choix 1</h2></v-col>
                        <v-col class="d-flex align-center justify-end py-0"><div style="height:35px;"><img class="" style="width:100% ; height:100%;" src="../../public/Resources/images/icon/expand_more_white_24dp.svg"/></div></v-col>
                      </v-row>
                    </v-expansion-panel-title>
                    <v-expansion-panel-text class="v-secondary-light-1"><p class="f-primary" v-html="$t('section.test')"></p></v-expansion-panel-text>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
            -->

            <v-row v-if="this.Product.included">
              <v-col cols="12" class="">
                <button style="width: 100%;" @click="showSelectedInclued = true"  class="mt-1">
                    <div  class="btnKit">
                      <h2 class="kitText d-flex align-center justify-center" v-html="$t('section.btnKit')"></h2>                   
                    </div>
                </button>
              </v-col>
            </v-row>
            
            <div class="mt-10">
              <!--select^^-->
              <v-row >
                <v-col class="d-flex justify-center px-0">
                  <a href="/Resources/documents/whyUS.pdf" target="_blank">
                    <div class="Icon">
                      <img class="itemHover" style="position: absolute; z-index: 2; " :src="$t('icon.whyHover')"/>
                      <img class="" style="position: relative; z-index: 1; " :src="$t('icon.why')"/>
                    </div>
                  </a>
                </v-col>
                <v-col class="d-flex justify-center px-0">
                  <div v-if="Product.technicalURL">
                    <a :href="Product.technicalURL" target="_blank">
                      <div class="Icon">
                        <img class="itemHover" style="position: absolute; z-index: 2; " :src="$t('icon.TechnialSheetHover')"/>
                        <img style="position: relative; z-index: 1; " :src="$t('icon.TechnialSheet')"/>
                      </div>
                    </a>
                  </div>
                  <div v-else><img style="position: relative; z-index: 1; " :src="$t('icon.TechnialSheetDisable')"/></div>
                </v-col>
                <v-col class="d-flex justify-center px-0">
                  <a href="/Resources/documents/guide_installation_porte_de_garage_3D_2019.pdf" target="_blank">
                    <div class="Icon">
                      <img class="itemHover" style="position: absolute; z-index: 2; " :src="$t('icon.installationHover')"/>
                      <img class="" style="position: relative; z-index: 1; " :src="$t('icon.installation')"/>
                    </div>
                  </a>
                </v-col>
                <v-col class="d-flex justify-center px-0">
                  <a href="/Resources/documents/warranty.pdf" target="_blank">
                    <div class="Icon">
                      <img class="itemHover" style="position: absolute; z-index: 2; " :src="$t('icon.warrantyHover')"/>
                      <img class="" style="position: relative; z-index: 1; " :src="$t('icon.warranty')"/>
                    </div>
                  </a>
                </v-col>
              </v-row>
              
              <v-col cols="12" class="d-flex justify-center"><div style="width: 80%; margin: 30px 0px 10px 0px; border-bottom: solid 2px black;"></div></v-col>
              <v-row class="ma-0">
                <v-col class="d-flex justify-center justify-sm-start align-center py-0 boxAddLeft"><h2 class="priceText oswald2">{{Product.price}}$</h2></v-col>
                <v-col class="d-flex justify-center justify-sm-end align-center txt-c py-0 boxAddRight">
                  <h2 v-if="Product.price && Product.qty > 0" @click="AddItemInOrder()" class="addText uppercase cursor oswald2 txt-c" v-html="$t('section.add')"></h2>
                  <h2 v-else class="addText uppercase oswald2" v-html="$t('section.available')" ></h2>
                </v-col>
              </v-row>
              <v-row v-if="Product.qty > 0">
                <v-col>
                  <p style="text-align: right;" class="oswald f-primary" >** {{$t('section.qty')}} : {{ Product.qty }} **</p>
                </v-col>
              </v-row>

              <v-row class="ma-0">
                <v-col class="d-flex justify-center justify-sm-end py-0"><p @click="navigateBack()" class="backtext bold cursor uppercase" v-html="$t('section.back')"></p></v-col>
              </v-row>
            </div>

          </div>
        </v-col>
        <v-col cols="12" lg="7" style="position:relative">

        </v-col>
      </v-row>
      
      <v-dialog class="included" style="z-index: 10000; object-position: center center;" v-model="showSelectedInclued">
            <template v-slot:default="{ isActive }">
                <v-card class="custom-scrollbar">
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text="X" @click="isActive.value = false"><span class="bold" style="font-size: 22px;">X</span></v-btn>
                    </v-card-actions>
                    <v-card-text style="margin: 50px;">
                        <v-row class=""> 
                          <v-col cols="10" style="margin: auto auto; display: block;"><h2>Les inclus dans notre produit:</h2></v-col>
                          <v-col cols="8" style="margin: auto auto; display: block;"><p v-html="$filters.textWithBR(Product.included)"></p></v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </template>
        </v-dialog>
    </div>
    
</template>

<style scoped>
.cursor {cursor: pointer;}
p {font-size: 18px}
.gridpadProduct {padding:30px 10%;}
.btnKit {background-color: rgba(var(--secondary),0.9); color: rgb(var(--primary)); border-radius: 0px 20px;}
.btnKit:hover {background-color: rgb(var(--primary));color: rgb(var(--secondary));}
.kitText {color: rgb(var(--primary)); text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.25);}
.kitText:hover {color: rgb(var(--secondary)); text-shadow: 1px 1px 2px rgba(var(--primary),0.5);}
.whiteBG { height: 100%; width: 100%; background-color: white; }
.productImg { width: 100%; height: 100%; max-width: 700px; max-height: 800px; object-fit: scale-down !important; object-position: center center;}
  /*.productImg {height: 100%; width: 100%; max-width: 800px; position:absolute; left: 0;}*/
.priceText {color: rgb(var(--primary)); text-shadow: 1px 1px 4px #000; font-size:48px;}
.addText { color: rgb(var(--primary)); text-shadow: 2px 2px 4px rgba(var(--secondary),0.5); font-size:48px; font-weight: 200; }
.addText:hover { color: rgb(var(--secondary)); text-shadow: 2px 2px 4px rgba(var(--primary),0.5); }
.backtext {color: rgb(var(--secondary))}
.backtext:hover {text-decoration: underline;}
.Icon{  height:60px; width:191px;}
/* 
.guaranteeIcon {  background-image: url('../../public/Resources/images/icon/garantie.png'); background-position: center; }
.guaranteeIcon:hover { background-image: url('../../public/Resources/images/icon/garantie-hover.png'); } 
.installationIcon {  background-image: url('../../public/Resources/images/icon/Installation.png'); background-position: center; }
.installationIcon:hover { background-image: url('../../public/Resources/images/icon/Installation-hover.png'); }
.dataSheetIcon {  background-image: url('../../public/Resources/images/icon/FicheTechnique.png'); background-position: center; }
.dataSheetIcon:hover { background-image: url('../../public/Resources/images/icon/FicheTechnique-hover.png'); }
.whyUsIcon {  background-image: url('../../public/Resources/images/icon/whyUS.png'); background-position: center; }
.whyUsIcon:hover { background-image: url('../../public/Resources/images/icon/whyUS-hover.png'); }
*/
.boxAddLeft {max-width: 200px; margin: auto auto;}
.boxAddRight {min-width: 250px; margin: auto auto;}
.itemHover {opacity:0;}
.itemHover:hover {opacity:1;}
.included h2 { font-weight: 700; }
@media handheld, only screen and (max-width: 300px) {
  .boxAddRight {min-width: 200px; margin: auto auto;}
}
@media handheld, only screen and (max-width: 600px) {
  .addText {font-size:24px !important;}
}
@media handheld, only screen and (max-width: 754px) {
  
}
@media handheld, only screen and (max-width: 960px) {
  p {font-size: 16px}
  .gridpadProduct {padding:0px 15px !important;}
}
@media handheld, only screen and (max-width: 1279px) {
  .dm-none {display: none;}
  .gridpadProduct {padding:0px 50px;}
}
@media handheld, only screen and (max-width: 1378px) {}
@media handheld, only screen and (max-width: 1460px) {
  .boxAdd {min-width:370px;}
  .priceText {font-size:38px;}
  .addText {font-size:38px;}
}
</style>

<script>
import { apiECommerceExtension } from '@/mixins/apiECommerceExtension.js'
import pageTranslations from '../../src/assets/i18N/product.json';

export default {
  name: 'Product',
  mixins: [apiECommerceExtension],
  data: () => ({
    Product: {title:"product"} ,
    showSelectedInclued: false,
    image: 'https://abstracxionstorage.blob.core.windows.net/habitat3d/products/Default.jpg',
  }),
    beforeCreate: function () {
      this.$i18n.mergeLocaleMessage('fr', pageTranslations.fr);
      this.$i18n.mergeLocaleMessage('en', pageTranslations.en);
    },
  mounted: async function() {

      this.Product = await this.EcommerceProductGetById();

      if(this.Product.image.url){this.image = this.Product.image.url;}

  },
  methods:{
    navigateBack: function (route) {
      if (window.history.length > 1) {
          this.$router.go(-1);
      } else if (route) {
          this.$router.push(this.$router.push({ name: 'onlineStore'}));
      }
    },
    AddItemInOrder: async function(){
      if(this.Product && this.Product.price && this.Product.qty > 0 ) {
        
        let options = null;
        if(this.Product.included && this.Product.included !== "") { options = this.Product.included; }

        let added = await this.AddItem(this.Product,options);
        if(added == true){ this.$router.push({ name: 'order' }); }
      } else { }
      
    },
  },
  components: {

  }
}
</script>

<template>
  <v-app>
      <Cookie/>
        <Header />
      <div>
        <router-view/>
        <v-dialog fullscreen v-model="errorVisible" class="dialogErrorBox">
          <v-card class="dialogErrorCard">
            <v-card-title class="error" >Erreur</v-card-title>
            <v-card-text>
              <div class="txt-j">
                <p class="mt-5">Nous vous prions de bien vouloir revalider les informations que vous avez fournies afin de résoudre ce problème.</p>
                <p class="mt-5">Si le problème persiste,nous vous recommandons de contacter l'administrateur du site internet. </p>
                <v-card-subtitle class="error dialogErrorSubtitle">Indication spéciale</v-card-subtitle>
                <div class="dialogErrorMessage" style=" overflow-x: scroll;">
                  <v-row >
                    <p>{{errorMessage}}</p>
                  </v-row>
                </div>
              </div>
              
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="errorVisible = false" style="color: rgb(var(--primary)) !important; padding: 18px !important;">Compris</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>     
      <Footer />
  </v-app>
</template>

<style>
  .dialogErrorBox { width: 50% !important; height: 50%; margin: auto auto !important;}
  .dialogErrorCard {padding:18px !important;}
  .dialogErrorSubtitle {font-weight: 700; margin: 10px 0px !important;}
  .dialogErrorMessage {padding: 18px !important; max-height: 100px; scroll-behavior: smooth; overflow-y:scroll; background-color: rgba(var(--secondary),0.3); overflow-x:hidden;}
  @media handheld, only screen and (max-width: 600px) {}
  @media handheld, only screen and (max-width: 960px) {}
  @media handheld, only screen and (max-width: 1279px) {}
  @media handheld, only screen and (max-width: 1378px) {}
</style>
<script>
  import Cookie from '../src/components/cookie.vue';
  export default {
    name: 'App',
    data: () => ({
      errorVisible: false,
      error: null,
      errorMessage: "",
    }),
    mounted: async function () {
      $App.event.$on("app_error", this.appErrorhappened);
    },
    methods:{
      appErrorhappened(params) {
          if (params instanceof Error) {
              this.errorMessage = "name:" + params.name + " msg: " + params.message
          } else { this.errorMessage = params; }
          this.errorVisible = true;

          console.error(params);
          //alert(params);
      },
    },
    components: {
      Cookie,
    }
  }
</script>


<template>
    <div>
        <!-- desktop-->
        <div class="desktop">
            <v-row class="ma-0">
                <v-col cols="12" class="blackBox d-flex justify-end align-center">
                    <router-link v-if="this.orderLength > 0" class="cartIconM" to="/order" style="position: relative;"> <div class="lengthCircleM">{{this.orderLength}}</div></router-link>
                    <router-link v-if="this.invoiceRef" class="orderInvoiceM" to="/invoice" style="position: relative;"></router-link>
                    <div v-if="$i18n.locale != 'en'" @click="switchLanguage()" class="F-IE bold" style=""><a class="nolang bold ">EN</a><a class="f-primary bold" style="font-size: 22px;"> | </a> <a class="lang bold ">FR</a></div>
                    <div v-if="$i18n.locale != 'fr'" @click="switchLanguage()" class="F-IE f-primary bold"><a class="lang bold ">EN</a><a class="f-primary bold" style="font-size: 22px;"> | </a> <a class="nolang bold "> FR</a></div>                    
                </v-col>
                <v-col cols="12" class="pa-0">
                    <div class="bgHeaderBox">
                        <div class="spacingMenu">
                            <div class="logo">
                                <img src="../../public/Resources/images/logo/Habitat3D.png" alt="logo"/>
                            </div>
                        </div>
                    </div>
                </v-col>
                <!--Menu-->
                <v-col cols="12" class="pa-0 redBox ">
                    <v-row class=" ma-0 d-flex justify-start align-center widthBox" style=" position: relative; z-index: 10000; margin-right: auto !important; margin-left: auto !important;">
                        <div v-for="item in menu" :key="item" class="categories">
                            <h2 class="align-center"><router-link :to="item" class=" d-flex" v-html="$t(item.title)"></router-link> </h2>
                            <div class="subMenu " v-if="item.subMenu">
                                <div v-for="childItem in item.subMenu" :key="childItem"> 
                                    <router-link  :to="childItem.path"  v-html="$t(childItem.subTitle)" class="subText"></router-link> 
                                </div>
                            </div>
                        </div>
                    </v-row>
                </v-col>
            </v-row>
        </div>

        <!--Mobile-->
        <div class="mobile">
            <v-row no-gutters>
                <v-app-bar class="menuM blackBox2" style="overflow-x: hidden;">
                    <v-col cols="2" sm="1" class="d-flex align-center justify-start"><img style="width:35px; height:35px;" @click.stop="this.drawer = !this.drawer" src="../../public/Resources/images/icon/menuRed.png"/></v-col>
                    <v-col cols="7" sm="9" class="d-flex align-center justify-center">
                        <v-row>
                            <v-col cols="12" class="d-flex justify-center"><router-link to="/home" class="mt-3"><img class="mLogo" src="../../public/Resources/images/logo/Habitat3D.png" /></router-link></v-col>
                        </v-row>
                    </v-col>
                    
                    <v-col  cols="3" sm="2" class="d-flex align-center justify-end pr-6">
                        <a v-if="this.orderLength > 0" class="cartIconM" href="/order" style="position: relative;"> <div class="lengthCircleM">{{this.orderLength}}</div></a>
                        <a v-if="this.invoiceRef" class="orderInvoiceM" :href="'/invoice'" style="position: relative;"></a>
                        
                    </v-col>
                </v-app-bar>

                <v-navigation-drawer class="drawer" v-model="this.drawer" location="top">
                    <v-list>
                        <v-list-item class="pa-0 ma-0"><router-link to="/home"><h4 class="menu bold uppercase">{{ $t('header.home') }}</h4></router-link></v-list-item>
                        <v-list-item class="pa-0 ma-0"><router-link to="/concept"><h4 class="menu bold uppercase">{{ $t('header.concept') }}</h4></router-link></v-list-item>
                        <v-list-item class="pa-0 ma-0">
                            <v-expansion-panels >
                                <v-expansion-panel class="bgBlack">
                                    <v-expansion-panel-title class="pl-5 pr-0 bgBlack">
                                        <v-row class="ma-0">
                                            <v-col cols="10" class="d-flex align-center justify-start pa-0 "><h4 class="menu bold uppercase px-0 py-2 ma-0">{{ $t('header.products') }}</h4></v-col>
                                            <v-col cols="2" class="d-flex align-center justify-end"><div style="height:35px;"><img class="" style="width:100% ; height:100%;" src="../../public/Resources/images/icon/expand_more_red_24dp.png"/></div></v-col>
                                        </v-row>
                                    </v-expansion-panel-title >
                                    <v-expansion-panel-text class="bgBlack"><router-link to="/home" class="menu bold uppercase sMenuSize" v-html="$t('header.habitat')"></router-link></v-expansion-panel-text>
                                    <v-expansion-panel-text class="bgBlack"><router-link to="/" class="menu bold uppercase sMenuSize" v-html="$t('header.garage')"></router-link></v-expansion-panel-text>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-list-item>
                        <v-list-item class="pa-0 ma-0"><router-link to="/boutique"><h4 class="menu bold uppercase">{{ $t('header.boutique') }}</h4></router-link></v-list-item>
                        <v-list-item><a class="langM bold F-IE" v-if="this.$i18n.locale == 'en'" @click="switchLanguage()">FR</a><a class="langM bold F-IE" v-if="this.$i18n.locale == 'fr'" @click="switchLanguage()">EN</a></v-list-item>
                    </v-list>
                </v-navigation-drawer>         
            </v-row>
        </div>
        
    </div>
    
</template>
<style scoped>
    .lang { font-size: 22px; margin: 10px 0px 10px 0px; padding: 10px 0px 10px 0px;  color: rgb(var(--primary)); text-shadow: 1px 1px 0px gray; text-decoration: underline;}
    .lang:hover {}
    .cartIcon{height: 100%; width: 35px; position: relative; float: right; background-image: url("../../public/Resources/images/icon/CartBlackIcon.png"); background-position: center center; background-repeat: no-repeat; background-size: contain;}
    .cartIcon:hover{background-image: url("../../public/Resources/images/icon/CartIconSecondary.png"); background-color: rgba(var(--secondary)) ; }
    .orderInvoice{height: 100%; width: 35px; position: relative; float: right;background-image: url("../../public/Resources/images/icon/invoiceBlack.png"); background-position: center center; background-repeat: no-repeat; background-size: contain;}
    .orderInvoice:hover{background-image: url("../../public/Resources/images/icon/invoiceWhite.png"); background-color: rgba(var(--secondary)) ; }
    .cartIconM{height: 35px; width: 35px; position: relative; float: right; background-image: url("../../public/Resources/images/icon/CartIconSecondary.png"); background-position: center center; background-repeat: no-repeat; background-size: contain;}
    .cartIconM:hover{background-image: url("../../public/Resources/images/icon/CartWhiteIcon.png");}
    .orderInvoiceM{height: 35px; width: 35px; position: relative; float: right;background-image: url("../../public/Resources/images/icon/invoiceWhite.png"); background-position: center center; background-repeat: no-repeat; background-size: contain;}
    .orderInvoiceM:hover{background-image: url("../../public/Resources/images/icon/invoiceWhite.png");}
    .lengthCircle{background-color: rgba(var(--secondary)) ; position: relative;  float: right; font-size: 11px; transform: translate(0%,220%); color: white; text-align: center; height: 14px; width: 14px; border-radius: 50%;}
    .lengthCircleM{background-color: rgba(var(--primary)) ; position: relative;  float: right; font-size: 11px; transform: translate(0%,160%); color: white; text-align: center; height: 14px; width: 14px; border-radius: 50%;}
    .nolang { cursor: pointer; width: auto;  font-size: 22px; margin: 10px 0px 10px 0px; padding: 10px 0px 10px 0px;  color: rgb(var(--primary));}
    .nolang:hover {color: rgba(var(--tierce),0.9); text-decoration: none;}
    .langM {cursor: pointer; font-size: 22px; margin: 10px 0px 10px 0px; padding: 10px 0px 10px 0px;  color: rgb(var(--primary)); text-shadow: 1px 1px 0px gray; text-decoration: underline;}
    .bgBlack {background-color: rgb(var(--secondary));}
    .blackBox {background-color: rgb(var(--secondary)); height:50px;}
    .blackBox2 {background-color: rgb(var(--secondary)); height:70px;}
    .redBox {background-color: rgb(var(--primary)); height:50px;}
    .widthBox {width:75%; height: 100%;}
    .categories a{font-size: 27px; font-family: oswald; letter-spacing: 2px; height: 100% !important; position: relative; color: black; height:50px !important; padding: 2px 15px; text-transform: uppercase; font-weight: 700; }
    .categories:hover a{background-color:black; color: white;} 
    .categories .subMenu a{background-color:black; color: red;}
    .categories .subMenu a:hover{background-color:black; color: white;} 
    .subMenu{ display: none; position:absolute; background-color:black; padding: 10px; }
    .categories:hover .subMenu { display: block;}
    .drawer { margin-top:-22px; padding-top: 20px !important; width:75% !important; height: 100% !important; background-color: black; opacity: 0.8;}
    /*#region Background*/
    .bgHeaderBox { position:relative; }
    .bgHeaderBox::before {
        content: ' '; display: block; position: absolute;
        width: 100%; height:100%;  
        left: 0; top: 0;

        background-image: url('../../public/Resources/images/header/headerBG.png');
        background-position:center;
        background-repeat: no-repeat; 
        background-size: cover;
        
    }
    .spacingMenu {position: relative; height: 450px; }
    .sMenuSize {font-size: 22px;}
    .logo { 
        position: absolute; 
        left: 48%; top: 0%; 
        transform: translate(-50%, 100%);}
    /*#endregion*/
    .desktop {display: block; top: 0;}
    .mobile {display: none;}
    .mLogo {height: 50px; width: auto;}
    
    @media handheld, only screen and (max-width: 450px) {
        .mLogo {height: 35px;}
        .drawer { width:100% !important; opacity: 0.9;}
        .sMenuSize {font-size: 18px;}
    }
    @media handheld, only screen and (max-width: 600px) {}
    @media handheld, only screen and (max-width: 960px) {
        .mDesktopHide {height:50px;}
        .cartIconM:hover{background-image: url("../../public/Resources/images/icon/CartBlackIcon.png");}
        .desktop {display: none;}
        .mobile {display: block; height:70px;}
    }
    @media handheld, only screen and (max-width: 1030px) {
        .widthBox { width: 80%;}
    }
    @media handheld, only screen and (max-width: 1279px) {}
    @media handheld, only screen and (max-width: 1378px) {}
</style>
<script>
    import { apiECommerceExtension } from '@/mixins/apiECommerceExtension.js';
    import translations from './../assets/i18N/global.json';
export default {
    name: 'Header',
    mixins: [apiECommerceExtension],
    data: () => ({
        drawer: false,
        orderLength: 0,
        invoiceRef: '',
        menu:[
            {title:'menu.home',path:'/home'},
            {title:'menu.concept',path:'/concept'},
            {   
                title:'menu.products',
                subMenu: [
                    {subTitle:'menu.product1',path:'/home'},
                    {subTitle:'menu.product2',path:'/'}
                ]
            },
            {title:'menu.store',path:'/boutique'}
        ]
    }),
    beforeCreate: function () {
        let lang = localStorage.getItem("Lang");
        if(lang){this.$i18n.locale = lang;} else { this.$i18n.locale = 'fr'; }

        this.$i18n.mergeLocaleMessage('fr', translations.fr);
        this.$i18n.mergeLocaleMessage('en', translations.en);
        },
    mounted() {
        this.orderLength = 0;
        this.orderLength = this.isOrder();
        this.invoiceRef = this.GetInvoiceRef();
        $App.event.$on("app_ecommerceOrderChanged", this.orderChanged);
    },
    methods: {
        switchLanguage: function () { 
            this.$i18n.locale = this.currentLang() === 'en' ? 'fr' : 'en';
            localStorage.setItem('Lang', this.$i18n.locale);
            window.location.reload(); 
        },
        currentLang: function() {
            this.setLang();
            return this.$i18n.locale;
        },
        setLang: function() {
            let lang = localStorage.getItem("Lang");
            if(lang){this.$i18n.locale = lang;} else { this.$i18n.locale = 'fr'; }
        },
        orderChanged: function() { this.orderLength = this.isOrder(); }
    },
    component: {}
}
</script>